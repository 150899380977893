import { Flex, InfoNote, Money, StatusBadge, Text, Tooltip } from '../../../ui-kit'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import colors from '../../../ui-kit/colors'
import Button from '../../../ui-kit/components/buttons/Button'
import React, { useMemo, useState } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import MenuDropdown from '../../../ui-kit/components/menuDropdown/MenuDropdown'
import DividedList from '../../../ui-kit/components/sidebar/DividedList'
import { getDisplayStatusValue } from '../../contracts/utils'
import SidebarLabel from '../../../ui-kit/components/sidebar/SidebarLabel'
import align from '../../../ui-kit/align'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { getMoreDropdownOptions, validateInvoice } from '../invoiceActions/InvoiceActions'
import InvoiceStatusIcons from '../invoiceActions/InvoiceStatusIcons'
import InvoiceActivities from '../invoiceActivity/InvoiceActivities'
import { getBillingContacts, recordTypes } from '../invoicesUtils'
import { useNavigate } from 'react-router-dom'
import MemoField from '../../../ui-kit/components/inputs/MemoField'
import { UpdateInvoiceMutation } from '../../../queriesUpdated/mutations/updateInvoice.gql'
import {
  InvoicesOutstandingIndexQuery,
  InvoiceOutstandingSidebarQuery,
} from '../../../queriesUpdated/queries/invoiceOutstanding.gql'
import { MarkInvoiceRetention } from '../../../queries/mutations/updateInvoiceRetention.gql'
import { ContractQuery } from '../../../queries/contracts.gql'
import Textarea from '../../../ui-kit/components/inputs/Textarea'
import RetentionIcon from '../../../ui-kit/components/retentionIcon/RetentionIcon'
import { VendorQuery } from '../../../queries/vendors.gql'
import ViewInvoiceLink from '../ViewInvoiceLink'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { merge } from 'lodash'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { fcIcon } from '../../../ui-kit/assets'

const SidebarInvoicesContent = ({
  data,
  setSelectedInvoice,
  setIsMarkAsDisputeModalOpened,
  setIsRequestPaymentModalOpened,
  setIsReceiveModalOpened,
  setIsPromiseToPayModalOpened,
  setIsPaymentPlanModalOpened,
  markAsResolvedHandler,
  setIsSidebarOpened,
  setAssignProjectModalOpened,
  contractId,
  setIsRefundModalOpened,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const currentUser = useCurrentUser()

  const { data: sidebarData } = useCustomQuery({
    query: InvoiceOutstandingSidebarQuery,
    queryOptions: {
      variables: { id: data?.id },
      skip: !data?.id,
    },
    rollbarOptions: {
      operationName: 'InvoiceOutstandingSidebarQuery',
      target: 'SidebarInvoicesContent',
    },
  })

  const invoiceData = useMemo(() => {
    const completeInvoice = merge({}, data, sidebarData?.invoice)
    return {
      ...completeInvoice,
      id: completeInvoice?.id,
      hashid: completeInvoice?.hashid,
      dispute: completeInvoice?.dispute,
      invoiceNumber: completeInvoice?.invoiceNumber,
      customerId: completeInvoice?.contract?.id,
      customerName: completeInvoice?.contract?.buyer?.name,
      totalOutstanding: completeInvoice?.outstandingAmountCents,
      projectStatus: completeInvoice?.project?.status,
      projectId: completeInvoice?.project?.id,
      project: completeInvoice?.project?.name || '-',
      po: completeInvoice?.po || '-',
      invoiceDate: <FormattedDate date={completeInvoice?.issueDate} />,
      dueDate: <FormattedDate date={completeInvoice?.maturityDate} />,
      billingContacts: getBillingContacts(completeInvoice?.contract?.buyer?.buyerUserMemberships),
      disputeDetails: completeInvoice?.dispute?.details,
    }
  }, [data, sidebarData])
  const onP2PAction = () => {
    setSelectedInvoice(data)
    setIsPromiseToPayModalOpened(true)
    setIsSidebarOpened(false)
  }
  const onPaymentPlanAction = () => {
    setSelectedInvoice(data)
    setIsPaymentPlanModalOpened(true)
    setIsSidebarOpened(false)
  }
  const onMarkAsDispute = () => {
    setIsMarkAsDisputeModalOpened(true)
    setIsSidebarOpened(false)
  }
  const onMarkAsResolved = () => {
    markAsResolvedHandler(invoiceData.dispute.id)
  }
  const onAssignToProject = () => {
    setIsSidebarOpened(false)
    setSelectedInvoice(data)
    setAssignProjectModalOpened(true)
  }
  const onRefund = () => {
    setIsRefundModalOpened(true)
    setIsSidebarOpened(false)
  }
  const descriptionData = [
    {
      label: t('location'),
      value: invoiceData.location?.name || '-',
      hidden: !invoiceData.location?.name,
    },
    {
      label: t('customer'),
      node: (
        <Button
          label={invoiceData.customerName}
          onClick={() => navigate(`/customers/${invoiceData.customerId}/overview`)}
          size={sizes.SM}
          testData="customer-link"
          variant={buttonsVariants.LINK}
        />
      ),
    },
    {
      label: t('project'),
      value: invoiceData.project === '-' && invoiceData.project,
      node: invoiceData.project !== '-' && (
        <Button
          className="overflow-hidden"
          label={invoiceData.project}
          onClick={() =>
            navigate(
              // eslint-disable-next-line max-len
              `/customers/${invoiceData.customerId}/projects/${invoiceData.projectStatus}?additionalFilters=id&filters=id.${invoiceData.projectId}&openSidebarParams=id.${invoiceData.projectId}`,
            )
          }
          size={sizes.SM}
          testData="project-link"
          variant={buttonsVariants.LINK}
        />
      ),
    },
    { label: t('po'), value: invoiceData.po, hidden: !invoiceData.po },
    { label: t('invoiceDate'), value: invoiceData.invoiceDate },
    { label: t('dueDate'), value: invoiceData.dueDate },
    {
      label: t('discountAmountCents'),
      value: <Money value={invoiceData.discountAmountCents} />,
      hidden: !invoiceData.discountAmountCents,
    },
    {
      label: t('discountCutOffDate'),
      value: !!invoiceData.discountCutoffDate && (
        <FormattedDate date={invoiceData.discountCutoffDate} />
      ),
      hidden: !invoiceData.discountAmountCents || !invoiceData.discountCutoffDate,
    },
  ]

  const billingData = invoiceData.billingContacts.map((contact) => [
    { label: t('name'), value: contact.fullName },
    { label: t('mobilePhone'), value: contact.formattedPhoneNumber },
    { label: t('officePhone'), value: contact.formattedOfficePhoneNumber },
    { label: t('email'), value: contact.email, color: colors.BLUE, email: true },
  ])

  const getValidError = (propName) =>
    validateInvoice(propName, [data]) ? t(validateInvoice(propName, [data])) : ''
  const showActions =
    setIsMarkAsDisputeModalOpened ||
    setIsRequestPaymentModalOpened ||
    setIsReceiveModalOpened ||
    setIsPromiseToPayModalOpened ||
    setIsPaymentPlanModalOpened ||
    setAssignProjectModalOpened
  const [memoFormIsDirty, setMemoFormIsDirty] = useState(false)
  const [updateInvoice, { loading: isUpdateInvoiceLoading }] = useCustomMutation({
    mutation: UpdateInvoiceMutation,
    rollbarOptions: { operationName: 'UpdateInvoiceMutation', target: 'SidebarInvoicesContent' },
    mutationOptions: {
      refetchQueries: [InvoiceOutstandingSidebarQuery],
    },
  })

  const [setRetention, { loading: isSetRetentionLoading }] = useCustomMutation({
    mutation: MarkInvoiceRetention,
    rollbarOptions: { operationName: 'UpdateInvoiceRetention', target: 'SidebarInvoicesContent' },
    mutationOptions: {
      refetchQueries: [InvoicesOutstandingIndexQuery, ContractQuery, VendorQuery],
    },
  })

  const markRetentionHandler = () => {
    if (isSetRetentionLoading) {
      return
    }

    setRetention({
      variables: {
        id: invoiceData.id,
        retention: !invoiceData.markedAsRetentionAt,
      },
    })
  }

  return (
    <div className="flex flex-col mt-[-1.5rem]">
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL2}>
            {t('invoicesDetails')}
          </Text>
          {invoiceData.recordType === recordTypes.FINANCE_CHARGE && (
            <div className="pl-2">
              <img alt="Suppli" className="h-5" src={fcIcon} />
            </div>
          )}
          <div className="pl-2">
            <InvoiceStatusIcons model={invoiceData} />
          </div>
        </div>

        <div className="flex flex-row">
          <div className="mr-2">
            <InfoNote label={t('number')}>
              <Text fontWeight={fontWeight.MEDIUM} size={sizes.SM}>
                {invoiceData.invoiceNumber}
              </Text>
            </InfoNote>
          </div>
          <ViewInvoiceLink id={invoiceData.hashid} />
        </div>
      </div>

      <div className="flex flex-row mt-3">
        <div className="w-6/12 flex flex-col">
          <SidebarLabel text="totalOutstanding" />
          <Money className="text-2xl mt-1" value={invoiceData.totalOutstanding} />
        </div>
        <div className="w-6/12 flex flex-col">
          <SidebarLabel text="status" />
          <Flex className="mt-2 ">
            <StatusBadge iconName="dot" value={getDisplayStatusValue(invoiceData?.overdueLevel)} />
            {invoiceData.markedAsRetentionAt && <RetentionIcon className={'ml-2'} />}
          </Flex>
        </div>
      </div>
      <DividedList content={descriptionData} />

      {!!billingData.length && (
        <div className="mt-5">
          <Text fontWeight={fontWeight.SEMIBOLD} size={sizes.BASE}>
            {billingData.length > 1 ? t('billingContacts') : t('billingContact')}
          </Text>
        </div>
      )}

      {billingData.map((data) => (
        <DividedList content={data} key={data[0].value} />
      ))}

      <div>
        {setIsReceiveModalOpened && (
          <Tooltip content={getValidError('receivePayment')}>
            <Button
              className="mt-8"
              disabled={validateInvoice('receivePayment', [data])}
              label={t('receivePayment')}
              onClick={() => {
                setSelectedInvoice(data)
                setIsReceiveModalOpened(true)
                setIsSidebarOpened(false)
              }}
              size={sizes.BASE}
              testData="receive-payment-sidebar"
              variant={buttonsVariants.PRIMARY}
              fullWidth
            />
          </Tooltip>
        )}
        <div className="flex flex-row text-black">
          {setIsRequestPaymentModalOpened && (
            <div className="mt-6 mr-1 w-6/12">
              <Tooltip content={getValidError('requestPayment')}>
                <Button
                  disabled={getValidError('requestPayment')}
                  label={t('requestPayment')}
                  onClick={() => {
                    setSelectedInvoice(data)
                    setIsRequestPaymentModalOpened(true)
                    setIsSidebarOpened(false)
                  }}
                  size={sizes.BASE}
                  testData="request-payment-sidebar"
                  variant={buttonsVariants.SECONDARY}
                  bordered
                  fullWidth
                />
              </Tooltip>
            </div>
          )}
          {showActions && (
            <div className="mt-6 ml-1 w-6/12">
              <MenuDropdown
                className="w-full"
                options={getMoreDropdownOptions(
                  {
                    onP2PAction,
                    onPaymentPlanAction,
                    onMarkAsDispute: invoiceData.dispute ? null : onMarkAsDispute,
                    onMarkAsRetention:
                      currentUser?.membershipRole === 'counter' || invoiceData.markedAsRetentionAt
                        ? null
                        : markRetentionHandler,
                    onUnmarkAsRetention:
                      currentUser?.membershipRole === 'counter' || !invoiceData.markedAsRetentionAt
                        ? null
                        : markRetentionHandler,
                    onMarkAsResolved: invoiceData.dispute ? onMarkAsResolved : null,
                    onAssignToProject,
                    onRefund:
                      invoiceData.outstandingAmountCents < invoiceData.amountCents
                        ? onRefund
                        : null,
                  },
                  [data],
                  t,
                )}>
                <Button
                  iconAlign={align.RIGHT}
                  iconName="chevronDown"
                  label={t('more')}
                  size={sizes.BASE}
                  testData="more-inv-sidebar"
                  variant={buttonsVariants.TERTIARY}
                  fullWidth
                />
              </MenuDropdown>
            </div>
          )}
        </div>
      </div>
      {invoiceData?.dispute && (
        <div className="w-full mt-6">
          <Textarea
            id="disputeDetails"
            inputClassName="h-32 w-full"
            label={t('disputeDetails')}
            name="disputeDetails"
            value={invoiceData.disputeDetails}
            disabled
          />
        </div>
      )}
      <MemoField
        closeForm={() => null}
        entityId={invoiceData.id}
        initialValue={invoiceData.memo}
        isFormDirty={memoFormIsDirty}
        isLoading={isUpdateInvoiceLoading}
        labelKey="notesOptional"
        placeholderKey="notes"
        responseEntityName={'updateInvoice'}
        setDirtyFormState={setMemoFormIsDirty}
        updateEntity={updateInvoice}
      />
      {(invoiceData?.dueDate.props.date || invoiceData.invoiceDate.props.date) && (
        <InvoiceActivities
          amountCents={invoiceData.amountCents}
          contractId={contractId}
          dueDate={invoiceData?.dueDate.props.date}
          invoiceData={invoiceData}
          invoiceDate={invoiceData.invoiceDate.props.date}
          invoiceId={invoiceData?.id}
        />
      )}
    </div>
  )
}

SidebarInvoicesContent.propTypes = {
  data: PT.object,
  contractId: PT.string,
  customerName: PT.string,
  markAsResolvedHandler: PT.func,
  setIsMarkAsDisputeModalOpened: PT.func,
  setIsRequestPaymentModalOpened: PT.func,
  setIsPromiseToPayModalOpened: PT.func,
  setSelectedInvoice: PT.func,
  setIsReceiveModalOpened: PT.func,
  setIsPaymentPlanModalOpened: PT.func,
  setIsSidebarOpened: PT.func,
  setAssignProjectModalOpened: PT.func,
  setIsRefundModalOpened: PT.func,
}

SidebarInvoicesContent.defaultProps = {
  data: {},
  contractId: '',
}

export default SidebarInvoicesContent
