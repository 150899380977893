import FormattedDate from '../../ui-kit/components/text/FormattedDate'
import { Money, Tooltip } from '../../ui-kit'
import React from 'react'
import { ContractQuery } from '../../queries/contracts.gql'
import sizes from '../../ui-kit/sizes'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import Button from '../../ui-kit/components/buttons/Button'
import { MONEY } from '../../utils/dataTypes'
import { userCompanyRoles } from '../../constants/userCompanyRoles'
import { paymentMethodTypes } from '../../constants/paymentResults'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import { fcIcon } from '../../ui-kit/assets'
import {
  getDifDays,
  getLuxonDate,
  JS_DATE,
  SERVER_DATE,
  SERVER_DATE_FORMAT,
} from '../../ui-kit/utils/dateUtils'
import { DateTime } from 'luxon'

export const recordTypes = {
  INVOICE: 'invoice',
  DEBIT: 'debit',
  FINANCE_CHARGE: 'finance_charge',
}

export const getCommonColumns = (
  t,
  isAllContracts,
  sideBarHandler,
  additionalFilters = [],
  loadFilter,
  selectedInvoices = [],
) => [
  {
    field: 'id',
    hide: true,
  },
  {
    field: 'issueDate',
    headerName: t('invoiceDate'),
    renderCell: (values) => <FormattedDate date={values?.row?.issueDate} />,
    filterId: 'issueDate',
    flex: 10,
  },
  {
    field: 'invoiceNumber',
    headerName: t('number'),
    filterId: additionalFilters.includes('invoicedNumber') && 'invoicedNumber',
    renderCell: (values) =>
      selectedInvoices?.length ? (
        <span className={'text-ellipsis overflow-hidden'} testData="invoice-details">
          {values?.row?.invoiceNumber}
        </span>
      ) : (
        <Button
          label={values?.row?.invoiceNumber}
          onClick={() => sideBarHandler(values)}
          size={sizes.SM}
          testData="invoice-details"
          variant={buttonsVariants.LINK}
        />
      ),
    flex: 15,
  },
  {
    field: 'customerName',
    headerName: t('customer'),
    renderCell: (values) => values?.row?.contract?.buyer?.name,
    filterId: isAllContracts && 'contractId',
    loadOptions: loadFilter?.loadContractOptions,
    flex: 21.25,
    hide: !isAllContracts,
  },
  {
    field: 'fcIcon',
    headerName: '',
    sortable: false,
    renderCell: (values) =>
      values.row.recordType === recordTypes.FINANCE_CHARGE && (
        <Tooltip content={t('financeCharges')}>
          <img alt="Suppli" className="h-5" src={fcIcon} />
        </Tooltip>
      ),
    flex: 2.5,
  },
  {
    field: 'projectName',
    headerName: t('project'),
    filterId: 'projectName',
    loadOptions: loadFilter?.loadProjectOptions,
    flex: 20,
    renderCell: (values) => (
      <span className={'text-ellipsis overflow-hidden'}>{values?.row?.project?.name}</span>
    ),
  },
  {
    field: 'po',
    headerName: t('po'),
    renderCell: (values) => (
      <span className={'text-ellipsis overflow-hidden'}>{values?.row?.po || '-'}</span>
    ),
    flex: 10,
  },
  {
    field: 'amountCents',
    headerName: t('invoiced'),
    renderCell: (values) => <Money value={values?.row?.amountCents} />,
    filterId: 'invoicedAmount',
    filterDataType: MONEY,
    flex: 10,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'locationName',
    filterId: 'locationName',
    filterTitle: t('invoiceLocation'),
    hide: true,
  },
]

export const getInvoiceBreadcrumbs = (contractData, t, scope) => {
  return contractData?.id
    ? [
        { label: t('customers'), link: '/customers' },
        contractData?.buyer
          ? { label: contractData?.buyer?.name, link: `/customers/${contractData.id}/overview` }
          : null,
        { label: t('invoices'), link: `/customers/${contractData.id}/invoices` },
        { label: t(scope) },
      ]
    : [{ label: t('invoices'), link: '/invoices' }, { label: t(scope) }]
}

export const getPaymentTypesOptions = (data, t) => {
  const paymentMethods = data.paymentMethods
  if (!paymentMethods) {
    return []
  }
  const creditCardOptions = paymentMethods
    .filter((method) => method.type === 'CreditCardPaymentMethod')
    .map((method) => ({
      ...method,
      label: method.nickname ? `${method.title} (${method.nickname})` : method.title,
      value: method.id,
      id: method.id,
      typeLabel: t('creditCard'),
      allowsAuthorization: method.allowsAuthorization,
    }))
  const achOptions = paymentMethods
    .filter((method) => method.type === 'AchPaymentMethod')
    .map((method) => ({
      ...method,
      label: method.nickname ? `${method.title} (${method.nickname})` : method.title,
      value: method.id,
      id: method.id,
      typeLabel: t('bankTransfer'),
      allowsAuthorization: method.allowsAuthorization,
    }))

  const types = [
    {
      name: 'creditCard',
      label: t('card'),
      value: 'creditCard',
      options: creditCardOptions,
    },
    {
      name: 'bankTransfer',
      label: t('bankTransfer'),
      value: 'bank-transfer',
      options: achOptions,
    },
  ].map((type) => ({ ...type, isHidden: !type.options.length }))

  const payCheck = paymentMethods.find((method) => method.type === 'PaycheckPaymentMethod')
  if (payCheck) {
    types.push({
      id: payCheck.id,
      name: 'cashCheck',
      type: paymentMethodTypes.PAYCHECK_PAYMENT_METHOD,
      label: t('cashCheck'),
      value: payCheck.id,
      options: [],
      allowsAuthorization: payCheck.allowsAuthorization,
    })
  }

  return types
}

export const getContractData = (contractID) => {
  const { data, loading } = useCustomQuery({
    query: ContractQuery,
    queryOptions: {
      variables: { id: contractID },
      skip: !contractID,
      notifyOnNetworkStatusChange: false,
    },
    rollbarOptions: { operationName: 'ContractQuery', target: 'invoicesUtils' },
  })
  const contractData = data?.contract
  return { contractData, loading }
}

export const scopeFilterMap = {
  outstanding: {
    key: 'outstanding_amount',
    values: ['1', null],
  },
  paid: {
    key: 'outstanding_amount',
    values: ['0', '0'],
  },
  financeCharges: { key: 'has_action_entity', values: ['has_finance_charges'] },
}

export const depositStatuses = {
  OPEN: 'open',
  APPLIED: 'applied',
  PENDING: 'pending',
  SENT: 'sent',
  CANCELLED: 'cancelled',
  AUTHORIZED: 'authorized',
}

export const depositStatusMapping = {
  [depositStatuses.OPEN]: { label: 'open', color: 'green' },
  [depositStatuses.APPLIED]: { label: 'applied', color: 'orange' },
  [depositStatuses.PENDING]: { label: 'pending', color: 'gray' },
  [depositStatuses.SENT]: { label: 'sent', color: 'bordered' },
  [depositStatuses.CANCELLED]: { label: 'cancelled', color: 'white' },
  [depositStatuses.AUTHORIZED]: { label: 'authorized', color: 'bordered' },
}

export const penaltyStatuses = {
  OPEN: 'penalty_open',
  PAID: 'penalty_paid',
  WAIVED: 'penalty_waived',
}

export const penaltyStatusMapping = {
  [penaltyStatuses.OPEN]: { label: 'open', color: 'orange' },
  [penaltyStatuses.PAID]: { label: 'paid', color: 'green' },
  [penaltyStatuses.WAIVED]: { label: 'waived', color: 'yellow' },
}

export const creditStatuses = {
  OPEN: 'open',
  APPLIED: 'applied',
  INITIAL: 'initial',
  PAID: 'paid',
}

export const creditStatusMapping = {
  [creditStatuses.OPEN]: { label: 'open', color: 'green' },
  [creditStatuses.APPLIED]: { label: 'applied', color: 'orange' },
}

export const getBillingContacts = (buyerUserMemberships = []) => {
  if (!buyerUserMemberships.length) {
    return []
  }

  const { billingContacts, primaryContacts } = buyerUserMemberships.reduce(
    (acc, membership) => {
      if (membership.membershipRole === userCompanyRoles.PRIMARY_CONTACT) {
        acc.primaryContacts.push(membership.buyerUser)
      } else if (membership.membershipRole === userCompanyRoles.BILLING_CONTACT) {
        acc.billingContacts.push(membership.buyerUser)
      }

      return acc
    },
    {
      billingContacts: [],
      primaryContacts: [],
    },
  )

  return billingContacts.length ? billingContacts : primaryContacts
}

export const getLoadingFunc =
  (fetch, entity, firstOption = null, variables = {}) =>
  async (search, values, selectedRecords) => {
    try {
      const isFirstOptionEmpty =
        firstOption &&
        values[0] &&
        values[0].label === firstOption.label &&
        values[0].value === firstOption.value
      const valuesLength = isFirstOptionEmpty && firstOption ? values?.length - 1 : values?.length
      const nextPage = !valuesLength ? 1 : parseInt(valuesLength / 10) + 1 || 1

      const { data: loadedData } = await fetch({
        page: nextPage,
        search: search || '',
        perPage: selectedRecords?.length || void 0,
        selectedItems: selectedRecords,
        ...variables,
      })

      if (loadedData) {
        return {
          options:
            !firstOption || (firstOption && isFirstOptionEmpty)
              ? loadedData[entity].data
              : [firstOption, ...loadedData[entity].data],
          hasMore:
            loadedData[entity].paginationData &&
            loadedData[entity].paginationData.to < loadedData[entity].paginationData.totalCount,
        }
      }
    } catch (err) {
      return {
        options: [],
        hasMore: false,
      }
    }
  }

export const getInvoicesPastDueTotalAmount = (
  invoices = [],
  creditCardFeeInvoiceDaysPastDueThreshold,
  totalCreditDeposit,
) => {
  const filteredInvoices = invoices.filter((invoice) =>
    invoice.maturityDate
      ? Math.trunc(
          getDifDays(DateTime.now(), DateTime.fromFormat(invoice.maturityDate, SERVER_DATE_FORMAT)),
        ) > creditCardFeeInvoiceDaysPastDueThreshold
      : false,
  )
  const totalPeymentAmount = filteredInvoices.reduce((acc, item) => {
    acc += item.paymentCents
    return acc
  }, 0)

  return totalPeymentAmount > totalCreditDeposit ? totalPeymentAmount - totalCreditDeposit : 0
}

export const PAYMENT = 'payment'
export const DEPOSIT = 'deposit'
export const PAYMENT_PLAN = 'payment_plan'

export const getConvenienceFee = (data) => {
  const {
    paymentSettings,
    contractCreditCardFeeEnabled,
    amount,
    invoices,
    type,
    totalCreditDeposit,
  } = data

  if (!paymentSettings?.creditCardFeeEnabled || !contractCreditCardFeeEnabled) {
    return 0
  }

  if (type === PAYMENT_PLAN) {
    if (paymentSettings.creditCardFeeForPaymentPlansEnabled) {
      return (paymentSettings.creditCardFeePercentage * amount) / 100
    }
  }

  if (type === DEPOSIT) {
    if (!paymentSettings.creditCardFeeForDepositsEnabled) {
      return 0
    }
  }

  if (
    !paymentSettings.creditCardFeePaymentAmountCentsThresholdEnabled &&
    (!paymentSettings.creditCardFeeInvoiceDaysPastDueThresholdEnabled || type === DEPOSIT)
  ) {
    return (amount * paymentSettings.creditCardFeePercentage) / 100
  }

  if (
    paymentSettings.creditCardFeePaymentAmountCentsThresholdEnabled &&
    amount > paymentSettings.creditCardFeePaymentAmountCentsThreshold
  ) {
    return (amount * paymentSettings.creditCardFeePercentage) / 100
  }

  if (paymentSettings.creditCardFeeInvoiceDaysPastDueThresholdEnabled) {
    return (
      (getInvoicesPastDueTotalAmount(
        invoices,
        paymentSettings.creditCardFeeInvoiceDaysPastDueThreshold,
        totalCreditDeposit,
      ) *
        paymentSettings.creditCardFeePercentage) /
      100
    )
  }

  return 0
}

export const getCombinedErrors = (validationErrors) =>
  validationErrors
    ? validationErrors.reduce((acc, item) => {
        if (!item) {
          return acc
        }

        Object.keys(item).forEach((key) => {
          if (!acc[key]) {
            acc[key] = item[key]
          }
        })

        return acc
      }, {})
    : {}

export const getAvailableDiscount = (
  rollbar,
  discountCutoffDate,
  paymentDate,
  discountAmountCents,
) => {
  if (
    !discountCutoffDate ||
    getDifDays(
      getLuxonDate(paymentDate, JS_DATE, rollbar),
      getLuxonDate(discountCutoffDate, SERVER_DATE, rollbar),
    ) < 1
  ) {
    return discountAmountCents || 0
  }

  return 0
}

export const checkSupportPartialDiscount = (
  isSupportPartialDiscount,
  discountAmountCents,
  { paymentCents, outstandingAmountCents },
) => {
  let appliedDiscountAmountCents

  if (isSupportPartialDiscount) {
    appliedDiscountAmountCents =
      discountAmountCents <= paymentCents ? discountAmountCents : paymentCents
  } else {
    appliedDiscountAmountCents =
      outstandingAmountCents > paymentCents || paymentCents < discountAmountCents
        ? 0
        : discountAmountCents
  }
  return appliedDiscountAmountCents
}

export const getDiscount = ({
  invoice,
  paymentMethodType,
  selectedPaymentDate,
  isSupportPartialDiscount = false,
  rollbar,
}) => {
  if (!invoice?.paymentMethodDiscounts || !selectedPaymentDate || !paymentMethodType) {
    return {
      discountAmountCents: 0,
      appliedDiscountAmountCents: 0,
    }
  }

  const paymentMethodDiscount = invoice.paymentMethodDiscounts.find(
    (method) => method.paymentMethodType === paymentMethodType,
  )
  const discountAmountCents = getAvailableDiscount(
    rollbar,
    invoice.discountCutoffDate,
    selectedPaymentDate,
    paymentMethodDiscount?.amountCents,
  )
  const appliedDiscountAmountCents = checkSupportPartialDiscount(
    isSupportPartialDiscount,
    discountAmountCents,
    { ...invoice },
  )

  return {
    discountAmountCents,
    appliedDiscountAmountCents,
  }
}

export const getInvoicesWithUpdatedDiscounts = ({
  invoices,
  paymentMethodType,
  paymentDate,
  isSupportPartialDiscount,
  rollbar,
}) => {
  if (!invoices?.length) {
    return []
  }

  return invoices.map((invoice) => {
    const { discountAmountCents, appliedDiscountAmountCents } = getDiscount({
      invoice,
      paymentMethodType,
      selectedPaymentDate: paymentDate,
      isSupportPartialDiscount,
      rollbar,
    })

    return {
      ...invoice,
      discountAmountCents,
      appliedDiscountAmountCents,
    }
  })
}
