import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Navigation from './Navigation'
import MainHeader from './MainHeader'
import { UserQuery } from '../../queries/users.gql'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { applyCurrentUserUpdate } from '../../store/actions/auth'
import { useDispatch } from 'react-redux'
import { VendorQuery } from '../../queries/vendors.gql'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { LG } from '../../constants/screenSizes'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import Memo from '../../ui-kit/components/memo/Memo'
import { identityUserpilot } from '../../utils/userpilot'
import { getErpMetadata } from '../../utils/parseErpMetadata'
import AppInfoMsg from '../AppInfoMsg'
import FrontChat from '../FrontChat'

const MainLayout = () => {
  const { width } = useWindowDimensions()
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(width < LG)

  useEffect(() => {
    setIsMenuCollapsed(width < LG)
  }, [width])

  const currentUser = useCurrentUser()
  const dispatch = useDispatch()
  const userData = useCustomQuery({
    fetchPolicy: 'cache-first',
    query: UserQuery,
    queryOptions: {
      variables: { id: currentUser?.id },
      skip: !currentUser?.id,
    },
    rollbarOptions: { operationName: 'UserQuery', target: 'MainLayout' },
  })
  const vendorData = useCustomQuery({
    query: VendorQuery,
    rollbarOptions: { operationName: 'VendorQuery', target: 'MainLayout' },
  })

  useEffect(() => {
    const erpMetadata = getErpMetadata(vendorData)

    const user = {
      ...currentUser,
      ...userData?.data?.vendorUser,
      vendorId: userData?.data?.currentVendor?.id,
      vendorName: vendorData?.data?.currentVendor?.name,
      paymentSettings: vendorData?.data?.currentVendor?.paymentSettings,
      vendorPreference: vendorData?.data?.currentVendor?.vendorPreference,
      timeZone: vendorData?.data?.currentVendor?.timeZone,
      erpMetadata,
    }
    dispatch(applyCurrentUserUpdate(user))
    identityUserpilot(user)
  }, [userData?.data?.user?.id, vendorData?.data])

  return (
    <>
      <AppInfoMsg />
      <FrontChat />
      <div className="flex overflow-hidden bg-gray-100">
        <div className="h-screen md:flex md:flex-shrink-0 z-30 fixed">
          <Navigation isMenuCollapsed={isMenuCollapsed} />
        </div>

        <div className={`flex flex-col w-full z-10 ${width < LG ? 'pl-20' : 'pl-44'}`}>
          <MainHeader isMenuCollapsed={isMenuCollapsed} />

          <div className="pt-20 w-full p-6 min-h-screen">
            <Outlet />
          </div>
          <Memo />
        </div>
      </div>
    </>
  )
}

export default MainLayout
