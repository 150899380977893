import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import {
  EmptyResultBody,
  EventBox,
  Flex,
  FormattedDate,
  LoadingSpinner,
  Money,
  Text,
  ConfirmationDialog,
} from '../../ui-kit'
import colors from '../../ui-kit/colors'
import sizes from '../../ui-kit/sizes'
import { useQueryParams } from '../../hooks/useQueryParams'
import { toInteger } from 'lodash'
import { PaymentPromisesQuery } from '../../queries/paymentPromises.gql'
import fontWeight from '../../ui-kit/fontWeight'
import StatusBadge from '../../ui-kit/components/badges/StatusBadge'
import { statusP2PMapping } from './promiseToPay/util'
import PromiseToPayContent from './promiseToPay/PromiseToPayContent'
import { DateTime } from 'luxon'
import { getPaginationData, parseFilterQuery } from '../../utils/utils'
import { useOpenEntitySidebar } from '../../hooks/useOpenEntitySidebar'
import UpdatePromiseToPayModal from './promiseToPay/UpdatePromiseToPayModal'
import FiltersComponent from '../../components/filters/FiltersComponent'
import InfiniteScroll from 'react-infinite-scroller'
import Sidebar from '../../ui-kit/components/sidebar/Sidebar'
import { useNotifications } from '../../hooks/useNotifications'
import { CancelPaymentPromise } from '../../queries/mutations/cancelPaymentPromiseRequest.gql'
import { ContractEventsQuery } from '../../queries/contractEvents.gql'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import { getPaymentsBreadcrumbs } from './paymentsUtils'
import { getLoadingFunc } from '../invoices/invoicesUtils'
import { ContractsAutocompleteQuery } from '../../queriesUpdated/queries/contract.gql'
import { useDataStorage } from '../../hooks/useDataStorage'

const PromisesToPay = ({ contractData, isAllContracts, scope }) => {
  const { queryParams } = useQueryParams()
  const { setBreadcrumbs } = useBreadcrumbs()
  const { t } = useTranslation()
  const [isSidebarOpened, setIsSidebarOpened] = useState(false)
  const [isUpdatePromiseModalOpened, setIsUpdatePromiseModalOpened] = useState(false)
  const [selectedPromise, setSelectedPromise] = useState({})
  const [availableFilters, setAvailableFilters] = useState(null)
  const filtersQuery = queryParams.filters || null
  const userFilters = useMemo(() => parseFilterQuery(filtersQuery), [filtersQuery])
  const additionalFilters = useMemo(
    () => (queryParams?.additionalFilters || '').split(','),
    [queryParams?.additionalFilters],
  )

  useEffect(() => {
    setBreadcrumbs(getPaymentsBreadcrumbs(contractData, t, scope))
  }, [])

  const page = toInteger(queryParams.page) || 1
  const search = queryParams.search

  const queryVariables = useMemo(() => {
    const variables = {
      filters: [
        ...userFilters,
        {
          key: 'created_at',
          values: [DateTime.now().minus({ month: 1 }).toISO()],
        },
      ],
      sort: 'id.desc',
      page,
      search,
    }

    if (contractData?.id) {
      variables.filters.push({
        key: 'contract_id',
        values: [contractData.id],
      })
    }

    return variables
  }, [contractData?.id, userFilters, search, page])

  const onCompleted = useCallback((response) => {
    response?.paymentPromises?.availableFilters &&
      setAvailableFilters(response.paymentPromises.availableFilters)
  }, [])
  const { data, loading, refetch, error } = useCustomQuery({
    query: PaymentPromisesQuery,
    onCompleted,
    queryOptions: {
      variables: queryVariables,
      notifyOnNetworkStatusChange: false,
    },
    rollbarOptions: { operationName: 'PaymentPromisesQuery', target: 'PromisesToPay' },
  })
  const promisesToPay = data?.paymentPromises.data || []

  const renderSubtitle = (promiseToPay) => (
    <Flex>
      {isAllContracts && (
        <>
          <Text className="leading-4" color={colors.GREY} size={sizes.SM} nowrap>
            {t('customerId')}:
          </Text>
          <Text
            className="border-r border-gray-200 px-4 leading-4"
            color={colors.GREY}
            size={sizes.SM}
            nowrap>
            {promiseToPay.contract?.referenceId}
          </Text>
        </>
      )}

      <Text
        className={cx('leading-4', isAllContracts && 'pl-4')}
        color={colors.GREY}
        size={sizes.SM}
        nowrap>
        {t('createdBy')}:
      </Text>
      <Text
        className="border-r border-gray-200 px-4 leading-4"
        color={colors.GREY}
        size={sizes.SM}
        nowrap>
        {promiseToPay.createdBy?.fullName}
      </Text>

      <Text className="pl-4 leading-4" size={sizes.SM}>
        <Text className="leading-4" color={colors.GREY} size={sizes.SM} nowrap>
          {t('createdOn')}:
        </Text>
        <Text className="px-4 leading-4" size={sizes.SM} nowrap>
          <FormattedDate date={promiseToPay.createdAt} />
        </Text>
      </Text>
    </Flex>
  )
  const renderTitle = (promiseToPay) => {
    return (
      <>
        {isAllContracts && (
          <>
            {promiseToPay.contract?.buyer?.name}
            <br />
          </>
        )}
        {t('p2pId')}: {promiseToPay.id} | {t('amount')}:
        <Text className="ml-2" fontWeight={fontWeight.MEDIUM} size={sizes.XL}>
          <Money value={promiseToPay.amountCents} />
        </Text>
      </>
    )
  }

  const handleRowClick = (promiseToPay) => {
    setSelectedPromise(promiseToPay)
    setIsSidebarOpened(true)
  }

  const closeForm = useCallback(() => {
    setIsSidebarOpened(false)
  }, [setIsSidebarOpened, setSelectedPromise])

  useOpenEntitySidebar(promisesToPay, setSelectedPromise, setIsSidebarOpened)

  const ref = useRef()

  const { refetch: refetchContractsLoadingData } = useCustomQuery({
    query: ContractsAutocompleteQuery,
    rollbarOptions: { operationName: 'ContractsAutocompleteQuery', target: 'InvoicesOutstanding' },
  })
  const loadContractOptions = getLoadingFunc(refetchContractsLoadingData, 'contractsAutocomplete')

  const columns = useMemo(
    () => [
      {
        filterTitle: t('id'),
        filterId: additionalFilters.includes('id') && 'id',
      },
      {
        filterTitle: t('expectedPaymentDate'),
        filterId: 'date',
      },
      {
        filterTitle: t('status'),
        filterId: 'status',
      },
      {
        filterTitle: t('customer'),
        filterId: isAllContracts && 'contractId',
        loadOptions: loadContractOptions,
      },
      {
        field: 'homeLocation',
        filterId: isAllContracts && 'homeLocation',
        filterTitle: t('homeLocation'),
        hide: true,
      },
    ],
    [additionalFilters, isAllContracts, loadContractOptions],
  )
  const { items } = useDataStorage({
    data,
    entityName: 'paymentPromises',
  })
  const paginationData = useMemo(
    () => getPaginationData(data?.paymentPromises),
    [data?.paymentPromises],
  )
  const hasMore = useMemo(() => paginationData.to < paginationData.totalCount, [paginationData])
  const loadMore = useCallback(() => {
    if (loading || !hasMore) {
      return
    }

    refetch({
      ...queryVariables,
      page: Math.ceil(paginationData.to / 10 + 1),
    })
  }, [refetch, queryVariables, loading, hasMore, paginationData])
  const reloadData = useCallback(() => refetch({ ...queryVariables }), [refetch, queryVariables])
  const renderEmptyResultItem = useCallback(() => {
    return <EmptyResultBody error={error} onReloadClick={reloadData} />
  }, [error, reloadData])
  const renderItems = useMemo(
    () =>
      items.map((promiseToPay) => (
        <EventBox
          className="mb-4"
          date={promiseToPay.date}
          isSelected={promiseToPay.id === selectedPromise.id}
          key={promiseToPay.id}
          onClick={() => handleRowClick(promiseToPay)}
          subtitle={renderSubtitle(promiseToPay)}
          title={renderTitle(promiseToPay)}>
          <Flex alignItems="center">
            <StatusBadge
              color={statusP2PMapping[promiseToPay.status]?.color}
              value={t(statusP2PMapping[promiseToPay.status]?.label)}
            />
          </Flex>
        </EventBox>
      )),
    [items, selectedPromise],
  )
  const [isOpenedCancelModal, setIsOpenedCancelModal] = useState(false)
  const { newNotification } = useNotifications()

  const [cancelPaymentPromise, { loading: isCancelPaymentPromiseLoading }] = useCustomMutation({
    mutation: CancelPaymentPromise,
    rollbarOptions: { operationName: 'CancelPaymentPromise', target: 'PromisesToPay' },
    mutationOptions: {
      refetchQueries: [PaymentPromisesQuery, ContractEventsQuery],
    },
  })
  const handleCancelPaymentPromise = useCallback((id) => {
    cancelPaymentPromise({
      variables: { id },
    }).then(({ data }) => {
      const responseData = data?.cancelPaymentPromise || {}

      if (responseData?.entity) {
        newNotification({ success: t('paymentPromiseCanceled') })
        closeForm()
        setIsOpenedCancelModal(false)
      }
    })
  }, [])

  const handleCloseCancelPaymentPromiseModal = useCallback(() => {
    setIsOpenedCancelModal(false)
    setIsSidebarOpened(true)
  }, [setIsOpenedCancelModal, setIsSidebarOpened])

  return (
    <div className="pt-4">
      <Sidebar isSidebarOpened={isSidebarOpened} setIsSidebarOpened={closeForm}>
        <PromiseToPayContent
          data={selectedPromise}
          setIsOpenedCancelModal={setIsOpenedCancelModal}
          setIsSidebarOpened={setIsSidebarOpened}
          setIsUpdatePromiseModalOpened={setIsUpdatePromiseModalOpened}
        />
      </Sidebar>

      <FiltersComponent availableFilters={availableFilters} columns={columns} />
      <Flex className={'w-full'} column>
        <InfiniteScroll
          getScrollParent={() => ref?.current?.scrollComponent}
          hasMore={hasMore}
          initialLoad={false}
          loadMore={loadMore}
          loader={<LoadingSpinner loading />}
          pageStart={0}
          ref={ref}
          useWindow>
          {!items.length && !loading && renderEmptyResultItem?.()}
          {!loading && renderItems}
        </InfiniteScroll>
      </Flex>

      {isUpdatePromiseModalOpened && (
        <UpdatePromiseToPayModal
          data={selectedPromise}
          isOpened={isUpdatePromiseModalOpened}
          setIsOpenedModal={setIsUpdatePromiseModalOpened}
          setIsSidebarOpened={setIsSidebarOpened}
          setSelectedPromise={setSelectedPromise}
        />
      )}
      <ConfirmationDialog
        confirmationMessage={t('cancelP2PConfirmationWarn')}
        isOpened={isOpenedCancelModal}
        isSubmitButtonYesDisabled={isCancelPaymentPromiseLoading}
        onModalClose={() => setIsSidebarOpened(true)}
        onSubmitButtonNoClick={handleCloseCancelPaymentPromiseModal}
        onSubmitButtonYesClick={() => handleCancelPaymentPromise(selectedPromise.id)}
        setIsOpened={setIsOpenedCancelModal}
        title={t('cancelPromiseToPay')}
      />
    </div>
  )
}

PromisesToPay.propTypes = {
  scope: PT.string,
  contractData: PT.shape({
    aging: PT.number,
    id: PT.string,
    customerName: PT.string,
    outstandingAmountCents: PT.number,
    availableCreditAmountCents: PT.number,
    availableDepositAmountCents: PT.number,
    overdueLevelGroups: PT.arrayOf(
      PT.shape({
        amountCents: PT.number,
        id: PT.string,
        overdueLevel: PT.shape({
          id: PT.string,
          title: PT.string,
          overdueFrom: PT.string,
          overdueTo: PT.string,
        }),
      }),
    ),
    buyer: PT.shape({
      name: PT.string,
      paymentMethods: PT.arrayOf(
        PT.shape({
          id: PT.string,
          title: PT.string,
          type: PT.string,
        }),
      ),
    }),
  }),
  isAllContracts: PT.bool,
}

PromisesToPay.defaultProps = {
  scope: '',
  contractData: {},
  isAllContracts: false,
}

export default PromisesToPay
