import PT from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { UpdateBuyerUserMembershipMutation } from '../../../../../queries/mutations/usersRequest.gql'
import { BuyerUserNotificationsQuery } from '../../../../../queries/buyersUser.gql'
import { useNotifications } from '../../../../../hooks/useNotifications'
import { useCustomMutation } from '../../../../../hooks/useCustomMutation'
import BuyerUserForm from './BuyerUserForm'
import { useCustomQuery } from '../../../../../hooks/useCustomQuery'
import {
  getExtendedNotificationSettings,
  getMappedNotificationSettings,
  mapEditUserInitialValues,
} from './utils'
import { BUYER_USER_STATUSES, getUserStatus } from '../../../../settings/teamManagement/UserActions'

const EditBuyerUserForm = ({
  setIsOpenedModal,
  user,
  isFormDirty,
  setDirtyFormState,
  closeForm,
  requestClose,
  refetch,
  contractId,
  isInactiveContract,
  handleSendInvitation,
}) => {
  const { t } = useTranslation()
  const status = useMemo(() => getUserStatus(user), [user])

  const { data: notificationsData, loading: loadingNotificationData } = useCustomQuery({
    query: BuyerUserNotificationsQuery,
    queryOptions: {
      variables: { userId: user?.id, contractId },
    },
    rollbarOptions: { operationName: 'BuyerUserQuery', target: 'EditContact' },
  })

  const getInitValues = () => {
    const membershipsNotificationSettings =
      notificationsData?.buyerUserMembership?.notificationSettings
    const contractNotificationSettings = notificationsData?.contract?.contractNotificationSettings
    if (!membershipsNotificationSettings || !contractNotificationSettings) {
      return {}
    }

    const { notificationTypes: contractNotificationTypes } = contractNotificationSettings
    const { notificationTypes: userNotificationTypes } = membershipsNotificationSettings

    const extendedNotificationConfig = getExtendedNotificationSettings(
      contractNotificationTypes,
      userNotificationTypes,
    )

    return mapEditUserInitialValues(extendedNotificationConfig)
  }

  const initialValues = useMemo(
    () => ({
      ...user,
      phoneNumber: user.formattedPhoneNumber,
      officePhoneNumber: user.formattedOfficePhoneNumber,
      notificationSettings: getInitValues(),
    }),
    [user, notificationsData],
  )

  const onCompleted = useCallback(() => {
    setIsOpenedModal(false)
  }, [])
  const [updateUser, { loading }] = useCustomMutation({
    mutation: UpdateBuyerUserMembershipMutation,
    onCompleted,
    rollbarOptions: { operationName: 'UpdateBuyerUserMutation', target: 'EditBuyerUserForm' },
  })
  const { newNotification } = useNotifications()

  const handleSubmitForm = (values) => {
    const data = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      extension: values.extension,
      phoneNumber: values.phoneNumber,
      membershipRole: values.membershipRole,
      officePhoneNumber: values.officePhoneNumber,
      notificationTypes: getMappedNotificationSettings(values.notificationSettings),
    }

    const variables = {
      id: user.id,
      data: data,
    }
    updateUser({ variables }).then(({ data }) => {
      const responseData = data?.updateBuyerUserMembership || {}

      if (responseData?.entity) {
        newNotification({ success: t('userUpdatedSuccessfully') })
        refetch()
        if (status === BUYER_USER_STATUSES.INVITED && initialValues.email !== values.email) {
          handleSendInvitation({ id: user.id })
        }
      }
    })
  }

  return (
    <BuyerUserForm
      cancelButtonTestData="cancel-edit-user"
      closeForm={closeForm}
      handleSubmitForm={handleSubmitForm}
      initialValues={initialValues}
      isFormDirty={isFormDirty}
      isInactiveContract={isInactiveContract}
      isInvitationAccepted={user?.invitationAcceptedAt}
      loading={loading}
      loadingNotificationData={loadingNotificationData}
      requestClose={requestClose}
      setDirtyFormState={setDirtyFormState}
      submitButtonLabelKey="save"
      submitButtonTestData="save-edit-user"
      isEditMode
    />
  )
}

EditBuyerUserForm.propTypes = {
  closeForm: PT.func.isRequired,
  isFormDirty: PT.bool.isRequired,
  requestClose: PT.func.isRequired,
  refetch: PT.func,
  setIsOpenedModal: PT.func,
  setDirtyFormState: PT.func.isRequired,
  user: PT.shape({
    id: PT.string,
    email: PT.string,
    firstName: PT.string,
    lastName: PT.string,
    fullName: PT.string,
    role: PT.string,
    createdAt: PT.string,
    permissions: PT.string,
    formattedPhoneNumber: PT.string,
    formattedOfficePhoneNumber: PT.string,
    invitationAcceptedAt: PT.string,
  }).isRequired,
  isCompanyContact: PT.bool,
  contractId: PT.string,
  isInactiveContract: PT.bool,
  handleSendInvitation: PT.func,
}

EditBuyerUserForm.defaultProps = {
  isCompanyContact: false,
  refetch: () => {},
}

export default EditBuyerUserForm
