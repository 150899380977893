import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Impersonate, Invitation, Login } from './pages/auth'
import NotFound from './pages/NotFound'
import PrivateRoute from './auth/PrivateRoute'
import { Contracts } from './pages/contracts'
import { Dashboard } from './pages/dashboard'
import MainLayout from './components/main/MainLayout'
import ContractDetail from './pages/contracts/ContractDetail'
import { MyProfile } from './pages/myProfile'
import { Settings } from './pages/settings'
import { InvoicesPage } from './pages/invoicesPage'
import { Reports } from './pages/reports'
import { useDirtyFormAlert } from './hooks/useDirtyFormAlert'
import ResetPassword from './pages/auth/ResetPassword'
import SetNewPassword from './pages/auth/SetNewPassword'
import SelectWorkspace from './pages/auth/SelectWorkspace'
import PublicRoute from './auth/PublicRoute'
import { v4 as uuid } from 'uuid'
import { getFromSessionStorage, saveToSessionStorage } from './utils/sessionStorage'
import { Mixpanel } from './utils/mixpanel'
import { useCurrentUser } from './hooks/useCurrentUser'
import Notification from './ui-kit/components/notifications/Notification'
import RedirectRoute from './auth/RedirectRoute'
import PaymentsPage from './pages/paymentsPage/PaymentsPage'

const App = () => {
  const { resetDirtyFormAlertStates } = useDirtyFormAlert()
  const currentUser = useCurrentUser()

  useEffect(() => {
    document.title = 'Merchant Suppli'
    resetDirtyFormAlertStates()

    if (currentUser && currentUser.hashid) {
      Mixpanel.identify(currentUser.hashid)
    } else {
      const sessionId = getFromSessionStorage('session_uuid')
        ? getFromSessionStorage('session_uuid')
        : uuid()
      saveToSessionStorage('session_uuid', sessionId)
      Mixpanel.identify(sessionId)
    }
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<NotFound />} path="*" />
        <Route element={<PublicRoute />}>
          <Route element={<Login />} path="/auth/login" />
          <Route element={<ResetPassword />} path="/auth/login/reset_password" />
          <Route element={<SetNewPassword />} path="/auth/password_reset" />

          <Route element={<Invitation />} path="/auth/invitations" />

          <Route element={<Impersonate />} path="/auth/impersonate" />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route element={<SelectWorkspace />} path="/select_workspace" />

          <Route element={<MainLayout />}>
            <Route element={<Dashboard />} path="/dashboard" />

            <Route element={<MyProfile />} path="/myprofile/*" />

            <Route element={<ContractDetail />} path="/customers/:contractId/*" />

            <Route element={<Contracts />} path="/customers" />
            <Route element={<InvoicesPage />} path="/invoices/*" />
            <Route element={<PaymentsPage />} path="/payments/*" />
            <Route element={<Settings />} path="/settings/*" />
            <Route element={<Reports />} path="/reports" />

            <Route element={<RedirectRoute />} path="/" />
          </Route>
        </Route>
      </Routes>
      <Notification />
    </BrowserRouter>
  )
}

export default App
