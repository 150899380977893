import { useEffect } from 'react'

const FrontChat = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js'
    script.async = true
    document.body.appendChild(script)

    script.onload = () => {
      if (window.FrontChat) {
        window.FrontChat('init', {
          chatId: '7ff4af978f55b05a7fc3180e7c6fce29',
        })
      }
    }

    return () => {
      if (window.FrontChat) {
        window.FrontChat('shutdown')
      }
      document.body.removeChild(script)
    }
  }, [])

  return null // No UI elements to render
}

export default FrontChat
