import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Avatar, Flex, Icon, Text, Tooltip } from '../../ui-kit'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { applyUserSignOut } from '../../store/actions/auth'
import { useTranslation } from 'react-i18next'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import Breadcrumbs from '../../ui-kit/components/breadcrumbs/Breadcrumbs'
import MenuDropdown from '../../ui-kit/components/menuDropdown/MenuDropdown'
import PT from 'prop-types'
import axios from 'axios'
import fontWeight from '../../ui-kit/fontWeight'
import { tooltipTypes } from '../../ui-kit/components/tooltip/Tooltip'
import sizes from '../../ui-kit/sizes'
import { KNOWLEDGE_BASE, SUPPLI_SUPPORT_EMAIL } from '../../constants/contacts'
import { selectAuthToken } from '../../store/selectors/auth'

const MainHeader = ({ isMenuCollapsed }) => {
  const currentUser = useCurrentUser()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const authToken = useSelector(selectAuthToken)
  const { breadcrumbs } = useBreadcrumbs()
  const [isSupportOpen, setIsSupportOpen] = useState(false)
  const closeSupportView = useCallback(() => {
    setIsSupportOpen(false)
  }, [])
  useEffect(() => {
    if (isSupportOpen) {
      window.addEventListener('click', closeSupportView, false)
    } else {
      window.removeEventListener('click', closeSupportView, false)
    }
  }, [isSupportOpen])

  const openChatWidget = useCallback(() => {
    try {
      window.zE('messenger', 'open')
    } catch (error) {
      console.error(error)
    }
  }, [window.zE])

  const handleSignOut = async (e) => {
    e.preventDefault()
    dispatch(applyUserSignOut())
    navigate('/auth/login')
    await axios.delete('/vendor_users/logout', {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  }

  const supportContent = useMemo(
    () => (
      <>
        <div className="py-2 px-4">
          <Text color="text-black-500">{t('available247')}</Text>
        </div>
        <div className="py-2 px-4 hover:bg-primary-200 cursor-pointer" onClick={openChatWidget}>
          <Text>{t('chat')}</Text>
        </div>
        <div className="py-2 px-4 hover:bg-primary-200 cursor-pointer">
          <a className="hover:no-underline" href={`mailto:${SUPPLI_SUPPORT_EMAIL}`}>
            <Text>{`${t('email')} ${SUPPLI_SUPPORT_EMAIL}`}</Text>
          </a>
        </div>
        <div
          className="py-2 px-4 hover:bg-primary-200 cursor-pointer"
          onClick={() => window.open(KNOWLEDGE_BASE)}>
          <Text>{t('knowledgeBase')}</Text>
        </div>
      </>
    ),
    [t],
  )

  const dropdownOptions = useMemo(
    () => [
      { label: t('myProfile'), onClick: () => navigate('/myprofile') },
      { label: t('switchWorkspace'), onClick: () => navigate('/select_workspace') },
      { label: t('logOut'), onClick: handleSignOut },
    ],
    [t],
  )

  return (
    <Flex
      alignItems="center"
      className={`z-20 fixed left-0 w-full h-14 bg-white p-4 text-sm ${
        isMenuCollapsed ? 'pl-24' : 'pl-48'
      }`}
      justifyContent="between"
      row>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <Flex className="space-x-10">
        <Flex alignItems="center">
          <Text color="text-black-500" fontWeight={fontWeight.MEDIUM} size={sizes.LG}>
            {currentUser.vendorName}
          </Text>
        </Flex>

        <Flex
          alignItems="center"
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation()
            setIsSupportOpen(!isSupportOpen)
          }}>
          <Tooltip
            className="flex items-center pr-1"
            content={supportContent}
            isOpen={isSupportOpen}
            placement="bottom"
            type={tooltipTypes.LIGHT}>
            <>
              <Icon color="text-warmBlack-400" name="globeAlt" size={sizes.LG} />
              <Text
                className="pl-2"
                color="text-black-500"
                fontWeight={fontWeight.MEDIUM}
                size={sizes.LG}>
                {t('support')}
              </Text>
            </>
          </Tooltip>
        </Flex>

        <Flex alignItems="center" row>
          <div className="p-2">
            <MenuDropdown options={dropdownOptions}>
              <Avatar firstName={currentUser.firstName} lastName={currentUser.lastName} />
            </MenuDropdown>
          </div>
        </Flex>
      </Flex>
    </Flex>
  )
}

MainHeader.propTypes = {
  isMenuCollapsed: PT.bool,
}
MainHeader.defaultProps = {
  isMenuCollapsed: false,
}

export default MainHeader
