import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import {
  EventBox,
  Flex,
  FormattedDate,
  LoadingSpinner,
  Money,
  Text,
  StatusBadge,
  EmptyResultBody,
  ConfirmationDialog,
} from '../../ui-kit'
import colors from '../../ui-kit/colors'
import sizes from '../../ui-kit/sizes'
import { useQueryParams } from '../../hooks/useQueryParams'
import { toInteger } from 'lodash'
import { PaymentPlansQuery } from '../../queries/paymentPlans.gql'
import HorizontalActivity from './paymentPlan/HorizontalActivity'
import PaymentPlanContent from './paymentPlan/PaymentPlanContent'
import { getInstallmentActivity, statusPaymentPlanMapping } from './paymentPlan/util'
import { CancelPaymentPlan } from '../../queries/mutations/cancelPaymentPlanRequest.gql'
import { getPaginationData, parseFilterQuery } from '../../utils/utils'
import { useNotifications } from '../../hooks/useNotifications'
import { useOpenEntitySidebar } from '../../hooks/useOpenEntitySidebar'
import InfiniteScroll from 'react-infinite-scroller'
import FiltersComponent from '../../components/filters/FiltersComponent'
import Sidebar from '../../ui-kit/components/sidebar/Sidebar'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import { getPaymentsBreadcrumbs } from './paymentsUtils'
import { getLoadingFunc } from '../invoices/invoicesUtils'
import { ContractsAutocompleteQuery } from '../../queriesUpdated/queries/contract.gql'
import fontWeight from '../../ui-kit/fontWeight'
import { useDataStorage } from '../../hooks/useDataStorage'

const PaymentPlans = ({ contractData, isAllContracts, scope }) => {
  const { t } = useTranslation()
  const { queryParams } = useQueryParams()
  const { setBreadcrumbs } = useBreadcrumbs()
  const [isSidebarOpened, setIsSidebarOpened] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState({})
  const [availableFilters, setAvailableFilters] = useState(null)
  const { newNotification } = useNotifications()
  const filtersQuery = queryParams.filters || null
  const userFilters = useMemo(() => parseFilterQuery(filtersQuery), [filtersQuery])
  const additionalFilters = useMemo(
    () => (queryParams?.additionalFilters || '').split(','),
    [queryParams?.additionalFilters],
  )

  useEffect(() => {
    setBreadcrumbs(getPaymentsBreadcrumbs(contractData, t, scope))
  }, [contractData, scope])

  const sort = queryParams.sort
  const page = toInteger(queryParams.page) || 1
  const search = queryParams.search

  const queryVariables = {
    sort,
    page,
    search,
    filters: userFilters,
  }

  if (contractData?.id) {
    queryVariables.filters.push({
      key: 'contract_id',
      values: [contractData.id],
    })
  }

  const onCompleted = useCallback((response) => {
    response?.paymentPlans?.availableFilters &&
      setAvailableFilters(response.paymentPlans.availableFilters)
  }, [])
  const { data, loading, refetch, error } = useCustomQuery({
    query: PaymentPlansQuery,
    onCompleted,
    queryOptions: {
      variables: queryVariables,
      notifyOnNetworkStatusChange: false,
    },
    rollbarOptions: { operationName: 'PaymentPlansQuery', target: 'PaymentPlans' },
  })

  const [cancelPaymentPlan, { loading: isCancelPaymentPlanLoading }] = useCustomMutation({
    mutation: CancelPaymentPlan,
    rollbarOptions: { operationName: 'CancelPaymentPlan', target: 'PaymentPlans' },
    mutationOptions: {
      refetchQueries: [PaymentPlansQuery],
    },
  })

  const paymentPlans = data?.paymentPlans.data || []

  useOpenEntitySidebar(paymentPlans, setSelectedPlan, setIsSidebarOpened)

  const renderDateLabel = (paymentPlan) => {
    if (paymentPlan?.status === 'failed') {
      return <Text color={colors.RED}>{t('failed')}</Text>
    }
    if (paymentPlan?.status === 'cancelled') {
      return <Text color={colors.RED}>{t('cancelled')}</Text>
    }
    return t('nextPayment')
  }

  const renderSubtitle = (paymentPlan) => (
    <Flex column>
      <Flex>
        <Text className="leading-4" color={colors.GREY} size={sizes.SM} nowrap>
          {t('createdBy')}:
        </Text>
        <Text
          className="border-r border-gray-200 px-4 leading-4"
          color={colors.GREY}
          size={sizes.SM}
          nowrap>
          {paymentPlan.createdBy?.fullName}
        </Text>

        <Text className="leading-4 pl-4" color={colors.GREY} size={sizes.SM} nowrap>
          {t('createdOn')}:
        </Text>
        <Text className="px-4 leading-4" size={sizes.SM} nowrap>
          <FormattedDate date={paymentPlan.createdAt} />
        </Text>
      </Flex>
      <Flex className="mt-1">
        {isAllContracts && (
          <>
            <Text className="leading-4" color={colors.GREY} size={sizes.SM} nowrap>
              {t('customerId')}:
            </Text>
            <Text
              className="border-r border-gray-200 px-4 leading-4"
              color={colors.GREY}
              size={sizes.SM}
              nowrap>
              {paymentPlan.contract?.referenceId}
            </Text>
          </>
        )}

        <Text
          className={cx('leading-4', isAllContracts && 'pl-4')}
          color={colors.GREY}
          size={sizes.SM}
          nowrap>
          {t('paymentMethod')}:
        </Text>
        <Text className="px-4 leading-4" size={sizes.SM} nowrap>
          {paymentPlan.paymentMethod?.title}
        </Text>
      </Flex>
    </Flex>
  )

  const handleRowClick = (paymentPlan) => {
    setSelectedPlan(paymentPlan)
    setIsSidebarOpened(true)
  }
  const renderTitle = (paymentPlan) => {
    return (
      <>
        {isAllContracts && (
          <>
            {paymentPlan.contract?.buyer?.name}
            <br />
          </>
        )}
        {t('planId')}: {paymentPlan.id} | {t('amount')}:
        <Text className="ml-2" fontWeight={fontWeight.MEDIUM} size={sizes.XL}>
          <Money value={paymentPlan.amountCents} />
        </Text>
      </>
    )
  }

  const handleCancelPaymentPlan = (id) => {
    cancelPaymentPlan({ variables: { id } }).then(({ data }) => {
      const responseData = data?.cancelPaymentPlan || {}

      if (responseData?.entity) {
        newNotification({ success: t('paymentPlanCanceled') })
      }

      setIsSidebarOpened(false)
    })
  }

  const ref = useRef()

  const { refetch: refetchContractsLoadingData } = useCustomQuery({
    query: ContractsAutocompleteQuery,
    rollbarOptions: { operationName: 'ContractsAutocompleteQuery', target: 'InvoicesOutstanding' },
  })
  const loadContractOptions = getLoadingFunc(refetchContractsLoadingData, 'contractsAutocomplete')

  const columns = useMemo(
    () => [
      {
        filterTitle: t('id'),
        filterId: additionalFilters.includes('id') && 'id',
      },
      {
        filterTitle: t('status'),
        filterId: 'status',
      },
      {
        filterTitle: t('customer'),
        filterId: isAllContracts && 'contractId',
        loadOptions: loadContractOptions,
      },
      {
        field: 'homeLocation',
        filterId: isAllContracts && 'homeLocation',
        filterTitle: t('homeLocation'),
        hide: true,
      },
    ],
    [additionalFilters, isAllContracts, loadContractOptions],
  )
  const { items } = useDataStorage({
    data,
    entityName: 'paymentPlans',
  })
  const paginationData = useMemo(() => getPaginationData(data?.paymentPlans), [data?.paymentPlans])
  const hasMore = useMemo(() => paginationData.to < paginationData.totalCount, [paginationData])
  const loadMore = useCallback(() => {
    if (loading || !hasMore) {
      return
    }

    refetch({
      ...queryVariables,
      page: Math.ceil(paginationData.to / 10 + 1),
    })
  }, [refetch, queryVariables, loading, hasMore, paginationData])
  const reloadData = useCallback(() => refetch({ ...queryVariables }), [refetch, queryVariables])
  const renderEmptyResultItem = useCallback(() => {
    return <EmptyResultBody error={error} onReloadClick={reloadData} />
  }, [error, reloadData])
  const renderItems = useMemo(
    () =>
      items.map((paymentPlan) => (
        <EventBox
          className="mb-4"
          date={paymentPlan?.nextInstallment?.date}
          dateLabel={renderDateLabel(paymentPlan)}
          isSelected={selectedPlan.id === paymentPlan.id}
          key={paymentPlan.id}
          onClick={() => handleRowClick(paymentPlan)}
          subtitle={renderSubtitle(paymentPlan)}
          title={renderTitle(paymentPlan)}>
          <Flex alignItems="center">
            <HorizontalActivity
              activity={getInstallmentActivity(paymentPlan.paymentPlanInstallments, t)}
            />
            <StatusBadge
              color={
                statusPaymentPlanMapping(
                  paymentPlan?.paymentPlanStatus || paymentPlan?.status,
                  paymentPlan.approvedAt,
                )?.color
              }
              value={t(
                statusPaymentPlanMapping(
                  paymentPlan?.paymentPlanStatus || paymentPlan?.status,
                  paymentPlan.approvedAt,
                )?.label,
              )}
            />
          </Flex>
        </EventBox>
      )),
    [items, selectedPlan, t],
  )
  const [isOpenedCancelModal, setIsOpenedCancelModal] = useState(false)
  const handleDelete = () => {
    handleCancelPaymentPlan(selectedPlan.id)
    setIsOpenedCancelModal(false)
  }

  const handleCloseCancelPaymentPlanModal = useCallback(() => {
    setIsOpenedCancelModal(false)
    setIsSidebarOpened(true)
  }, [setIsOpenedCancelModal, setIsSidebarOpened])

  return (
    <div className="pt-4">
      <Sidebar isSidebarOpened={isSidebarOpened} setIsSidebarOpened={setIsSidebarOpened}>
        <PaymentPlanContent
          data={selectedPlan}
          setIsOpenedCancelModal={setIsOpenedCancelModal}
          setIsSidebarOpened={setIsSidebarOpened}
        />
      </Sidebar>
      <FiltersComponent availableFilters={availableFilters} columns={columns} />
      <Flex className={'w-full'} column>
        <InfiniteScroll
          getScrollParent={() => ref?.current?.scrollComponent}
          hasMore={hasMore}
          initialLoad={false}
          loadMore={loadMore}
          loader={<LoadingSpinner loading />}
          pageStart={0}
          ref={ref}
          useWindow>
          {!items.length && !loading && renderEmptyResultItem?.()}
          {!loading && renderItems}
        </InfiniteScroll>
      </Flex>
      <ConfirmationDialog
        confirmationMessage={t('deletePaymentPlanWarning')}
        isOpened={isOpenedCancelModal}
        isSubmitButtonYesDisabled={isCancelPaymentPlanLoading}
        onModalClose={() => setIsSidebarOpened(true)}
        onSubmitButtonNoClick={handleCloseCancelPaymentPlanModal}
        onSubmitButtonYesClick={handleDelete}
        setIsOpened={setIsOpenedCancelModal}
        title={t('cancelPaymentPlan')}
      />
    </div>
  )
}
PaymentPlans.propTypes = {
  scope: PT.string,
  contractData: PT.shape({
    aging: PT.number,
    id: PT.string,
    customerName: PT.string,
    outstandingAmountCents: PT.number,
    availableCreditAmountCents: PT.number,
    availableDepositAmountCents: PT.number,
    overdueLevelGroups: PT.arrayOf(
      PT.shape({
        amountCents: PT.number,
        id: PT.string,
        overdueLevel: PT.shape({
          id: PT.string,
          title: PT.string,
          overdueFrom: PT.string,
          overdueTo: PT.string,
        }),
      }),
    ),
    buyer: PT.shape({
      name: PT.string,
      paymentMethods: PT.arrayOf(
        PT.shape({
          id: PT.string,
          title: PT.string,
          type: PT.string,
        }),
      ),
    }),
  }),
  isAllContracts: PT.bool,
}

PaymentPlans.defaultProps = {
  scope: '',
  contractData: {},
  isAllContracts: false,
}

export default PaymentPlans
