import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import PT from 'prop-types'
import { Button, Flex, Icon, Text, Dialog, Money, Tooltip } from '../../../ui-kit'
import colors from '../../../ui-kit/colors'
import { useTranslation } from 'react-i18next'
import { useDirtyFormAlert } from '../../../hooks/useDirtyFormAlert'
import NDropdown from '../../../ui-kit/components/dropdown/NDropdown'
import sizes from '../../../ui-kit/sizes'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import EmailForm from './EmailForm'
import { getModelsByIds } from '../../../utils/utils'
import getPresentationName, { requestSources } from '../../../utils/getPresentationName'
import { Field, Form } from 'react-final-form'
import TextForm from './TextForm'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { BuyerUsersForRequestsQuery } from '../../../queriesUpdated/queries/buyer.gql'
import Textarea from '../../../ui-kit/components/inputs/Textarea'
import fontWeight from '../../../ui-kit/fontWeight'
import DividedList from '../../../ui-kit/components/sidebar/DividedList'
import { InvoicesOutstandingForRequestQuery } from '../../../queriesUpdated/queries/invoiceOutstanding.gql'
import { getAvailableDiscount, getCombinedErrors, recordTypes } from '../invoicesUtils'
import { CreditsDepositsQuery } from '../../../queriesUpdated/queries/common.gql'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { FieldArray } from 'react-final-form-arrays'
import DataGridComponent from '../../../components/dataGrid/DataGridComponent'
import { parseWithMoneyFormat } from '../../../ui-kit/components/text/Money'
import Toggle from '../../../ui-kit/components/inputs/Toggle'
import CurrencyInput from '../../../ui-kit/components/inputs/CurrencyInput'
import arrayMutators from 'final-form-arrays'
import { FORM_ERROR } from 'final-form'
import { useRollbar } from '@rollbar/react'

const RequestPaymentModal = ({
  isOpened,
  setIsOpened,
  buyerName,
  onSubmit,
  buyerId,
  isLoading,
  invoicesIds,
  contractId,
  reopenSidebar,
}) => {
  const { t } = useTranslation()
  const rollbar = useRollbar()
  const currentUser = useCurrentUser()

  const buyerVariables = {
    id: buyerId,
    withAddresses: false,
  }
  const { data, loading: isUserListLoading } = useCustomQuery({
    query: BuyerUsersForRequestsQuery,
    queryOptions: {
      variables: buyerVariables,
      skip: !buyerId,
    },
    rollbarOptions: { operationName: 'BuyerUsersForRequestsQuery', target: 'RequestPaymentModal' },
  })
  const users = useMemo(
    () =>
      data && data?.buyer?.buyerUserMemberships
        ? data.buyer.buyerUserMemberships
            .map((membership) => {
              return {
                ...membership,
                ...membership.buyerUser,
              }
            })
            .filter((user) => user.membershipRole !== 'view_only')
        : [],
    [data],
  )

  const { data: invoiceData, loading: isInvoiceLoading } = useCustomQuery({
    query: InvoicesOutstandingForRequestQuery,
    queryOptions: {
      variables: {
        filters: [
          {
            key: 'id',
            values: [...invoicesIds],
          },
        ],
        perPage: invoicesIds.length,
      },
      skip: !invoicesIds?.length || !isOpened,
    },
    rollbarOptions: {
      operationName: 'InvoicesOutstandingForRequestQuery',
      target: 'RequestPaymentModal',
    },
  })

  const { data: creditsDeposits, loading: creditsDepositsLoading } = useCustomQuery({
    query: CreditsDepositsQuery,
    queryOptions: {
      skip: !contractId || !isOpened,
      variables: {
        filtersDeposits: [
          { key: 'contract_id', values: [contractId] },
          { key: 'outstanding_amount', values: ['1', ''] },
          { key: 'readonly', values: 'false' },
        ],
        filtersCredits: [
          { key: 'contract_id', values: [contractId] },
          { key: 'outstanding_amount', values: ['1', ''] },
          { key: 'readonly', values: 'false' },
        ],
      },
    },
    rollbarOptions: {
      operationName: 'CreditsDepositsQuery',
      target: 'RequestPaymentModal',
    },
  })

  const closeForm = useCallback(() => {
    setIsOpened(false)
    reopenSidebar()
  }, [setIsOpened])

  const [listOfRecipients, setListOfRecipients] = useState([])
  const [guestEmails, setGuestEmails] = useState([])
  const [guestPhones, setGuestPhones] = useState([])
  const [recipientsFullListValues, setRecipientsFullListValues] = useState([])
  const [guestEmailModalIsOpened, setGuestEmailModalIsOpened] = useState(false)
  const [guestPhoneModalIsOpened, setGuestPhoneModalIsOpened] = useState(false)

  const { requestClose } = useDirtyFormAlert({
    closeForm,
  })
  const onChangeRecipients = (e) => {
    setListOfRecipients(e.target.value)
    recipientsRef.current.handleExternalClose()
  }

  useEffect(() => {
    const emails = listOfRecipients
      .filter((value) => value && value.indexOf('email') > -1)
      .map((value) => value.replace('/email', ''))
    const phones = listOfRecipients
      .filter((value) => value && value.indexOf('phone') > -1)
      .map((value) => value.replace('/phone', ''))

    const selectedUserEmails = getModelsByIds(users, emails)
    const presentationValuesEmails = selectedUserEmails.map((user) => ({
      presentedName: getPresentationName({ user }, t, requestSources.EMAIL),
      sourceEmail: user.email,
      id: `${user.id}/email`,
    }))
    presentationValuesEmails.push(
      ...guestEmails.map((user) => ({
        presentedName: t('guestUser'),
        sourceEmail: user.email,
        id: user.email,
      })),
    )

    const selectedUserPhones = getModelsByIds(users, phones)
    const presentationValuesPhones = selectedUserPhones.map((user) => ({
      presentedName: getPresentationName({ user }, t, requestSources.PHONE),
      sourcePhone: user.formattedPhoneNumber,
      id: `${user.id}/phone`,
    }))
    presentationValuesPhones.push(
      ...guestPhones.map((user) => ({
        presentedName: t('guestUser'),
        sourcePhone: user.phone,
        id: user.phone,
      })),
    )
    setRecipientsFullListValues([...presentationValuesEmails, ...presentationValuesPhones])
  }, [listOfRecipients, guestEmails, guestPhones])
  const onEmailClick = useCallback(() => {
    setGuestEmailModalIsOpened(true)
  }, [])

  const onPhoneClick = useCallback(() => {
    setGuestPhoneModalIsOpened(true)
  }, [])

  const getRecipientsOptions = useCallback(() => {
    const emails = users.reduce((acc, user) => {
      if (!user.email) {
        return acc
      }

      acc.push({
        label: () => (
          <div className="text-sm ml-6">
            {getPresentationName({ user }, t, requestSources.EMAIL)}
          </div>
        ),
        value: `${user.id}/email`,
      })
      return acc
    }, [])
    const phones = users.reduce((acc, user) => {
      if (!user.formattedPhoneNumber) {
        return acc
      }

      acc.push({
        label: () => (
          <div className="text-sm ml-6">
            {getPresentationName({ user }, t, requestSources.PHONE)}
          </div>
        ),
        value: `${user.id}/phone`,
      })
      return acc
    }, [])
    emails.push({
      label: () => (
        <div
          className={`${colors.BLUE} capitalize text-sm w-[30rem] mb-2 ml-6`}
          onClick={onEmailClick}>
          {`+ ${t('addEmail')}`}
        </div>
      ),
      value: 'addEmail',
    })
    phones.push({
      label: () => (
        <div
          className={`${colors.BLUE} capitalize text-sm w-full w-[30rem] ml-6`}
          onClick={onPhoneClick}>
          {`+ ${t('addMobilePhone')}`}
        </div>
      ),
      value: 'addMobilePhone',
    })

    const recipients = []

    if (emails.length) {
      recipients.push({
        label: t('email'),
        options: emails,
      })
    }

    if (phones.length) {
      recipients.push({
        label: t('text'),
        options: phones,
      })
    }

    return recipients
  }, [t, users, onEmailClick, onPhoneClick])

  const handleAddNewEmail = (values) => {
    const copyGuestEmails = [...guestEmails]
    copyGuestEmails.push(values)
    setGuestEmails(copyGuestEmails)
    setGuestEmailModalIsOpened(false)
  }

  const handleAddNewPhone = (values) => {
    const copyGuestPhones = [...guestPhones]
    copyGuestPhones.push(values)
    setGuestPhones(copyGuestPhones)
    setGuestPhoneModalIsOpened(false)
  }

  const recipientsRef = useRef(null)

  const handleSubmitRequestPayment = (values) => {
    onSubmit(recipientsFullListValues, values)
  }

  const handleDelete = (item) => {
    const copyRecipients = listOfRecipients.filter((recipient) => recipient !== item.id)
    setListOfRecipients(copyRecipients)
    const copyGuestEmails = guestEmails.filter((recipient) => recipient.email !== item.id)
    setGuestEmails(copyGuestEmails)
    const copyGuestPhones = guestPhones.filter((recipient) => recipient.phone !== item.id)
    setGuestPhones(copyGuestPhones)
  }

  const getRequestSummary = (values) => {
    if (!invoiceData?.invoices?.data?.length) {
      return {}
    }

    const {
      totalPaymentCents,
      totalFinanceCharges,
      totalDiscountAmountCents,
      totalOutstandingAmountCents,
    } = invoiceData.invoices.data.reduce(
      (acc, invoice) => {
        if (!invoice) {
          return acc
        }

        if (invoice.recordType === recordTypes.INVOICE) {
          acc.totalOutstandingAmountCents += invoice.outstandingAmountCents
        }

        if (invoice.recordType === recordTypes.FINANCE_CHARGE) {
          acc.totalFinanceCharges += invoice.outstandingAmountCents
        }

        acc.totalPaymentCents += invoice.outstandingAmountCents
        acc.totalDiscountAmountCents += getAvailableDiscount(
          rollbar,
          invoice.discountCutoffDate,
          new Date(),
          invoice.discountAmountCents,
        )

        return acc
      },
      {
        totalPaymentCents: 0,
        totalFinanceCharges: 0,
        totalDiscountAmountCents: 0,
        totalOutstandingAmountCents: 0,
      },
    )

    const {
      anyCreditsDepositsExists,
      totalAppliedCreditAmountCents,
      totalAppliedDepositAmountCents,
    } = values.creditsDeposits.reduce(
      (acc, entity) => {
        if (entity.outstandingAmountCents) {
          acc.anyCreditsDepositsExists = true
        }

        if (entity.isEntityApplied && entity.type === 'credit') {
          acc.totalAppliedCreditAmountCents += entity.appliedAmountCents
        }

        if (entity.isEntityApplied && entity.type === 'deposit') {
          acc.totalAppliedDepositAmountCents += entity.appliedAmountCents
        }

        return acc
      },
      {
        anyCreditsDepositsExists: false,
        totalAppliedCreditAmountCents: 0,
        totalAppliedDepositAmountCents: 0,
      },
    )

    const netPaymentCents =
      totalPaymentCents -
      totalDiscountAmountCents -
      totalAppliedCreditAmountCents -
      totalAppliedDepositAmountCents

    return {
      netPaymentCents,
      totalFinanceCharges,
      totalDiscountAmountCents,
      anyCreditsDepositsExists,
      totalOutstandingAmountCents,
      totalAppliedCreditAmountCents,
      totalAppliedDepositAmountCents,
    }
  }

  const initialValues = useMemo(() => {
    const { deposits, credits } = creditsDeposits || {}
    const extendedCredits =
      credits?.data?.map((credit) => ({
        ...credit,
        type: 'credit',
        displayId: credit.referenceId,
        isEntityApplied: false,
        appliedAmountCents: 0,
      })) || []
    const extendedDeposits =
      deposits?.data.map((deposit) => ({
        ...deposit,
        type: 'deposit',
        displayId: currentUser.erpMetadata?.supportDeposits ? deposit.id : deposit.referenceId,
        isEntityApplied: false,
        appliedAmountCents: 0,
      })) || []

    return {
      memo: '',
      creditsDeposits: [...extendedCredits, ...extendedDeposits],
    }
  }, [creditsDeposits])

  const getCreditsDepositsRows = (fields) =>
    fields?.map((name, index) => {
      const entity = fields.value[index]
      return {
        fieldName: name,
        entityIndex: index,
        ...entity,
      }
    }) || []
  const getCreditsDepositsColumns = (form) => [
    {
      field: 'entityTypeAndAmount',
      headerName: '',
      renderCell: (values) => (
        <Flex className="h-full justify-evenly text-ellipsis overflow-hidden" column>
          <Tooltip content={values?.row?.memo}>
            <Text>{`${t(values?.row?.type)}: ${values?.row?.displayId}`}</Text>
          </Tooltip>
          <Tooltip content={values?.row?.project?.name}>
            <Text color="text-warmBlack-400 text-ellipsis overflow-hidden block max-w-[16rem]">{`${t(
              'project',
            )}: ${values?.row?.project?.name || '-'}`}</Text>
          </Tooltip>
          <Text color="text-warmBlack-400">
            {t('availableAmountHint', {
              amount: parseWithMoneyFormat(values?.row?.outstandingAmountCents),
            })}
          </Text>
        </Flex>
      ),
      flex: 3,
      sortable: false,
    },
    {
      field: 'enablingToggle',
      headerName: '',
      renderCell: (values) => (
        <Flex alignItems="center" className="h-full">
          <Field name={`${values?.row?.fieldName}.isEntityApplied`}>
            {({ input }) => (
              <Toggle
                className="pt-0"
                handleChange={(state) => {
                  if (!state) {
                    form.mutators.update('creditsDeposits', values?.row?.entityIndex, {
                      ...values?.row,
                      isEntityApplied: state,
                      appliedAmountCents: 0,
                    })
                    return
                  }

                  const formValues = form.getState().values
                  const paymentSummary = getRequestSummary(formValues)
                  const targetEntity = formValues.creditsDeposits[values?.row?.entityIndex]

                  let appliedAmountCents

                  if (
                    values?.row?.type === 'credit' &&
                    !currentUser.erpMetadata?.partialCreditApplicationEnabled
                  ) {
                    appliedAmountCents = targetEntity.outstandingAmountCents
                  } else if (
                    paymentSummary.netPaymentCents - targetEntity.outstandingAmountCents >=
                    0
                  ) {
                    appliedAmountCents = targetEntity.outstandingAmountCents
                  } else {
                    appliedAmountCents =
                      paymentSummary.netPaymentCents > 0 ? paymentSummary.netPaymentCents : 0
                  }

                  form.mutators.update('creditsDeposits', values?.row?.entityIndex, {
                    ...values?.row,
                    isEntityApplied: state,
                    appliedAmountCents,
                  })
                }}
                testData="entity-toggle"
                value={input.value}
              />
            )}
          </Field>
        </Flex>
      ),
      width: 70,
      sortable: false,
    },
    {
      field: 'appliedAmountCents',
      headerName: '',
      renderCell: (values) => (
        <Flex alignItems="center" className="h-full w-full" justifyContent="end">
          <Field name={`${values?.row?.fieldName}.appliedAmountCents`}>
            {({ input, meta }) =>
              values?.row?.type === 'credit' &&
              !currentUser.erpMetadata?.partialCreditApplicationEnabled ? (
                <Money className="items-center" value={input.value} />
              ) : (
                <CurrencyInput
                  disabled={!values?.row?.isEntityApplied}
                  error={meta.error}
                  id={`${values?.row?.id}-appliedAmountCents`}
                  onChange={(e) => {
                    const value = e.target.value
                    const newValue = Math.round(value * 100)
                    input.onChange(newValue)
                  }}
                  testData="entity-input"
                  value={input.value / 100}
                  isTableInput
                />
              )
            }
          </Field>
        </Flex>
      ),
      width: 120,
      sortable: false,
    },
  ]
  const handleValidateCreditsDeposits = (values) => {
    const errors = values.map((entity) => {
      const entityErrors = {}

      if (entity.appliedAmountCents > entity.outstandingAmountCents) {
        entityErrors.appliedAmountCents =
          entity.type === 'credit' ? t('invalidCreditTotal') : t('invalidDepositTotal')
      } else if (entity.isEntityApplied && entity.appliedAmountCents == 0) {
        entityErrors.appliedAmountCents = t('appliedAmountValidation')
      }

      return Object.keys(entityErrors).length ? entityErrors : undefined
    })

    return errors.filter(Boolean).length ? errors : undefined
  }

  const handleValidateForm = (values) => {
    let formError
    const paymentSummary = getRequestSummary(values)
    const { netPaymentCents } = paymentSummary

    if (netPaymentCents <= 0) {
      formError = t('requestedAmountValidation')
    }

    return {
      [FORM_ERROR]: formError,
    }
  }

  return (
    <>
      <Dialog
        isOpened={isOpened}
        paperClass="px-12 py-8"
        setIsOpened={requestClose}
        title={t('requestPayment')}
        titleClass={'font-semibold text-2xl'}>
        <div className="w-322" testdata="modal-requestPayment">
          <h4 className="text-sm font-medium mt-2 mb-6">{buyerName}</h4>

          <Form
            initialValues={initialValues}
            mutators={{ ...arrayMutators }}
            onSubmit={handleSubmitRequestPayment}
            render={({ handleSubmit, values, form, errors, error }) => {
              const requestSummary = getRequestSummary(values)
              const areCreditsDepositsAvailable = requestSummary.anyCreditsDepositsExists
              const descriptionData = [
                {
                  label: t('outstandingInvoices'),
                  value: <Money value={requestSummary.totalOutstandingAmountCents} />,
                },
                {
                  label: t('financeCharges'),
                  value: <Money value={requestSummary.totalFinanceCharges} />,
                },
                {
                  label: `(-) ${t('availableDiscounts')}`,
                  value: <Money value={requestSummary.totalDiscountAmountCents} />,
                },
                {
                  label: `(-) ${t('creditsApplied')}`,
                  value: <Money value={requestSummary.totalAppliedCreditAmountCents} />,
                },
                {
                  label: `(-) ${t('depositsApplied')}`,
                  value: <Money value={requestSummary.totalAppliedDepositAmountCents} />,
                },
              ]

              return (
                <form className="w-full flex gap-12" onSubmit={handleSubmit}>
                  <Flex className="w-1/3" column>
                    <Text className="pb-4" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL2}>
                      {t('summary')}
                    </Text>
                    <DividedList
                      content={descriptionData}
                      labelTextClassName="py-3"
                      valueTextClassName="py-3"
                      wrapperClassName="!m-0"
                    />
                    <Flex
                      alignItems="center"
                      className="border-t min-h-10"
                      justifyContent="between">
                      <Text fontWeight={fontWeight.BOLD} size={sizes.BASE}>
                        {t('amountRequested')}
                      </Text>
                      <Money
                        className="font-semibold text-xl"
                        value={requestSummary.netPaymentCents}
                      />
                    </Flex>
                    <Text className="pt-10 text-center" color="text-black-500">
                      {t('paymentRequestedWarning')}
                    </Text>
                    <div className="w-full mt-4 text-center">
                      <div className="text-error text-center" testData="error-global">
                        {error}
                      </div>
                    </div>
                    <Flex alignItems="end" className="flex-1 pt-6" justifyContent="center">
                      <Button
                        className={'mr-2'}
                        label={t('cancel')}
                        onClick={() => setIsOpened(false)}
                        size={sizes.SM}
                        testData="cancel-request-payment"
                        variant={buttonsVariants.TERTIARY}
                      />
                      <Button
                        className={'mr-2 px-4'}
                        disabled={
                          isLoading ||
                          !recipientsFullListValues.length ||
                          isInvoiceLoading ||
                          creditsDepositsLoading
                        }
                        label={t('requestPayment')}
                        size={sizes.SM}
                        testData="submit-request-payment"
                        type="submit"
                      />
                    </Flex>
                  </Flex>

                  <div className="w-[1px] bg-black-400" />

                  <Flex className="w-1/3" column>
                    <Text className="pb-4" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL2}>
                      {t('selectRecipients')}
                    </Text>
                    <NDropdown
                      inputClassName="w-full"
                      isDisabled={isUserListLoading}
                      label={t('addRecipients')}
                      listClass="max-h-40"
                      onChange={onChangeRecipients}
                      options={getRecipientsOptions()}
                      placeholder={t('addRecipients')}
                      ref={recipientsRef}
                      testData="recipients"
                      value={listOfRecipients.filter(
                        (value) => value !== 'addEmail' && value !== 'addMobilePhone',
                      )}
                      isHiddenInputValue
                      isMultipleSelect
                      withCheckmarks
                    />
                    <Flex className="w-full pt-5">
                      <div className="w-full">
                        <Text>{t('sendTo')}</Text>
                        <div className="w-full border mt-1 rounded-md h-40 max-h-40 py-2 px-2 overflow-y-auto">
                          {!recipientsFullListValues.length && (
                            <Text color="text-black-500">{t('addRecipients')}</Text>
                          )}
                          {!!recipientsFullListValues.length &&
                            recipientsFullListValues.map((item) => (
                              <Flex
                                alignItems="center"
                                justifyContent="between mb-1"
                                key={item.email}>
                                <Text className="w-[50%] pr-1">{item.presentedName}</Text>
                                <span className="w-[40%]">
                                  {!!item.sourceEmail && (
                                    <a
                                      className={'text-blue-500 hover:text-blue-500'}
                                      href={`mailto:${item.sourceEmail}`}>
                                      {item.sourceEmail}
                                    </a>
                                  )}
                                  {!!item.sourcePhone && (
                                    <a
                                      className={'text-blue-500 hover:text-blue-500'}
                                      href={`tel:${item.sourcePhone}`}>
                                      {item.sourcePhone}
                                    </a>
                                  )}
                                </span>
                                <Icon
                                  className="w-8 inline"
                                  color="text-warmBlack-400"
                                  name="trash"
                                  onClick={() => handleDelete(item)}
                                  type="outline"
                                />
                              </Flex>
                            ))}
                        </div>
                      </div>
                    </Flex>

                    <div className="w-full pt-5">
                      <Field name="memo">
                        {({ input }) => {
                          return (
                            <Textarea
                              id={input.name}
                              inputClassName={'h-28'}
                              label={t('memoOptional')}
                              name={input.name}
                              onChange={input.onChange}
                              value={input.value}
                            />
                          )
                        }}
                      </Field>
                    </div>
                  </Flex>

                  <div className="w-[1px] bg-black-400" />

                  <Flex className="w-1/3" column>
                    <Text className="pb-4" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL2}>
                      {t('selectCreditsDeposits')}
                    </Text>
                    {areCreditsDepositsAvailable ? (
                      <>
                        <FieldArray name="creditsDeposits" validate={handleValidateCreditsDeposits}>
                          {({ fields }) => (
                            <DataGridComponent
                              columns={getCreditsDepositsColumns(form)}
                              getRowHeight={() => 'auto'}
                              getRowId={(row) => `${row.type}-${row.id}`}
                              hideFooter={values.creditsDeposits.length <= 5000}
                              localPagination={values.creditsDeposits.length > 5000}
                              pageSize={5000}
                              rowClassName="center-alignment"
                              rowHeight={65}
                              rows={getCreditsDepositsRows(fields)}
                              testData="credits-deposits"
                              wrapperClassNames="header-hidden max-h-[396px] overflow-y-auto"
                            />
                          )}
                        </FieldArray>
                        <Flex
                          alignItems="center"
                          className="pt-5 px-3 border-t"
                          justifyContent="between">
                          <Text fontWeight={fontWeight.BOLD}>
                            {t('totalCreditsDepositsApplied')}
                          </Text>
                          <Money
                            className="text-xl font-semibold"
                            value={values.creditsDeposits.reduce(
                              (acc, entity) =>
                                entity.isEntityApplied ? acc + entity.appliedAmountCents : acc,
                              0,
                            )}
                          />
                        </Flex>
                        <div className="w-full mt-4 text-center min-h-[21px]">
                          {Object.entries(getCombinedErrors(errors.creditsDeposits)).map(
                            ([key, error]) => (
                              <div className="text-error" key={error} testData={`error-${key}`}>
                                {error}
                              </div>
                            ),
                          )}
                        </div>
                      </>
                    ) : (
                      <Flex className="py-[140px]" justifyContent="center">
                        <Text
                          color="text-warmBlack-400"
                          fontWeight={fontWeight.MEDIUM}
                          size={sizes.BASE}>
                          {t('noCreditsDepositsAvailable')}
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </form>
              )
            }}
            validate={handleValidateForm}
          />
        </div>
      </Dialog>

      <Dialog
        isOpened={guestEmailModalIsOpened}
        setIsOpened={setGuestEmailModalIsOpened}
        isInner
        shouldCloseOnBackdropClick>
        <EmailForm handleSubmitForm={handleAddNewEmail} />
      </Dialog>

      <Dialog
        isOpened={guestPhoneModalIsOpened}
        setIsOpened={setGuestPhoneModalIsOpened}
        isInner
        shouldCloseOnBackdropClick>
        <TextForm handleSubmitForm={handleAddNewPhone} />
      </Dialog>
    </>
  )
}

export default RequestPaymentModal

RequestPaymentModal.propTypes = {
  buyerId: PT.string,
  isOpened: PT.bool.isRequired,
  onSubmit: PT.func.isRequired,
  buyerName: PT.string,
  isLoading: PT.bool,
  contractId: PT.string,
  invoicesIds: PT.arrayOf(PT.string),
  setIsOpened: PT.func.isRequired,
  reopenSidebar: PT.func.isRequired,
}

RequestPaymentModal.defaultProps = {
  buyerName: undefined,
  isLoading: false,
}
