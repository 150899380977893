export const paymentPromiseStatuses = {
  ACTIVE: 'active',
  EXPIRED: 'expired',
  CANCELLED: 'cancelled',
  COMPLETED: 'completed',
}

export const statusP2PMapping = {
  [paymentPromiseStatuses.ACTIVE]: { label: 'active', color: 'gray' },
  [paymentPromiseStatuses.EXPIRED]: { label: 'expired', color: 'red' },
  [paymentPromiseStatuses.COMPLETED]: { label: 'completed', color: 'green' },
  [paymentPromiseStatuses.CANCELLED]: { label: 'cancelled', color: 'white' },
}
