import React, { useState } from 'react'
import appConfig from '../configs/appConfig'
import { Text } from '../ui-kit'
import { XIcon } from '@heroicons/react/outline'
import sizes from '../ui-kit/sizes'
const AppInfoMsg = () => {
  const [isOpenState, setIsOpenState] = useState(true)
  const close = () => {
    if (isOpenState) {
      setIsOpenState(false)
    }
  }
  return (
    <>
      {appConfig?.INFO_MSG && isOpenState && (
        <div className="w-full text-center py-4 absolute bg-orange-600 z-50">
          <Text className="pr-4" size={sizes.BASE}>
            {appConfig?.INFO_MSG}
          </Text>
          <XIcon aria-hidden="true" className="h-4 w-4 absolute right-4 top-4" onClick={close} />
        </div>
      )}
    </>
  )
}

export default AppInfoMsg
