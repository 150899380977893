import PT from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CreateBuyerUserMembershipMutation } from '../../../../../queries/mutations/usersRequest.gql'
import { useNotifications } from '../../../../../hooks/useNotifications'
import { useCustomMutation } from '../../../../../hooks/useCustomMutation'
import BuyerUserForm from './BuyerUserForm'
import {
  getExtendedNotificationSettings,
  getMappedNotificationSettings,
  mapEditUserInitialValues,
} from './utils'
import { useCustomQuery } from '../../../../../hooks/useCustomQuery'
import { ContractNotificationQuery } from '../../../../../queries/contracts.gql'
import { useBeforeUnload } from '../../../../../hooks/useBeforeUnload'
import { Button, Flex, Input } from '../../../../../ui-kit'
import { Field, Form } from 'react-final-form'
import AlertModal from '../../../../../ui-kit/components/alertModal/AlertModal'
import { validateEmail, validateRequiredField } from '../../../../../utils/validators'
import { BuyerUserQuery } from '../../../../../queries/buyersUser.gql'

const InviteBuyerUserForm = ({
  buyerId,
  setIsOpenedModal,
  refetch,
  closeForm,
  isFormDirty,
  requestClose,
  setDirtyFormState,
  contractId,
  isInactiveContract,
}) => {
  const { t } = useTranslation()
  const [inviteUserFormState, setInviteUserFormState] = useState(1)
  const [userEmail, setUserEmail] = useState()

  const { data: notificationsData, loading: loadingNotificationData } = useCustomQuery({
    query: ContractNotificationQuery,
    queryOptions: {
      variables: { id: contractId },
      skip: !userEmail,
    },
    rollbarOptions: { operationName: 'notificationsData', target: 'EditContact' },
  })
  const getInitValues = () => {
    if (!notificationsData?.contract?.contractNotificationSettings) {
      return {}
    }

    const { notificationTypes: contractNotificationTypes } =
      notificationsData.contract.contractNotificationSettings
    const userNotificationTypes = []

    const extendedNotificationConfig = getExtendedNotificationSettings(
      contractNotificationTypes,
      userNotificationTypes,
      true,
    )

    return mapEditUserInitialValues(extendedNotificationConfig)
  }

  const [create, { loading }] = useCustomMutation({
    mutation: CreateBuyerUserMembershipMutation,
    rollbarOptions: {
      operationName: 'CreateBuyerUserMembershipMutation',
      target: 'InviteBuyerUserForm',
    },
  })
  const { newNotification } = useNotifications()
  const createUser = async (variables) => {
    const { data } = await create({ variables })
    const responseData = data?.createBuyerUserMembership || {}

    if (responseData?.entity) {
      newNotification({ success: t('vendorUserAddedSuccessfully') })
      refetch()
      setIsOpenedModal(false)
    }
  }
  const handleSubmitForm = async (values) => {
    const data = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      extension: values.extension,
      phoneNumber: values.phoneNumber,
      membershipRole: values.membershipRole,
      officePhoneNumber: values.officePhoneNumber,
      notificationTypes: getMappedNotificationSettings(values.notificationSettings),
    }

    const variables = {
      data,
      buyerId,
      sendInvitation: values.sendInvitation,
    }

    const formError = await createUser(variables)

    if (formError) {
      return formError
    }
  }

  const {
    data: buyerUserData,
    loading: loadingUserData,
    refetch: refetchBuyer,
  } = useCustomQuery({
    query: BuyerUserQuery,
    queryOptions: {
      variables: { email: userEmail, buyerId: buyerId },
      skip: !userEmail,
    },
    rollbarOptions: { operationName: 'BuyerUserQuery', target: 'InviteBuyerUserForm' },
  })
  const buyerUser = buyerUserData?.buyerUser

  const getExistMembershipRole = useCallback(
    (buyerUserData, userEmail) => {
      if (!buyerUserData || !buyerUserData.buyer || !buyerUserData.buyer.buyerUserMemberships) {
        return
      }

      const membership = buyerUserData.buyer.buyerUserMemberships.find(
        (membership) => membership.buyerUser && membership.buyerUser.email === userEmail,
      )
      return membership ? membership.membershipRole : null
    },
    [buyerUserData, userEmail],
  )

  const initialValues = useMemo(
    () => ({
      email: userEmail,
      firstName: buyerUser?.firstName || '',
      lastName: buyerUser?.lastName || '',
      phoneNumber: buyerUser?.formattedPhoneNumber || '',
      membershipRole: '',
      sendInvitation: true,
      officePhoneNumber: buyerUser?.formattedOfficePhoneNumber || '',
      extension: buyerUser?.extension || '',
      notificationSettings: getInitValues(),
    }),
    [notificationsData, buyerUser],
  )

  const handleSubmitEmail = async (values) => {
    setUserEmail(values.email)
    const { data } = await refetchBuyer({ email: values.email, buyerId: buyerId })
    const result = getExistMembershipRole(data, values.email)
    if (result) {
      return { email: t('userAlreadyInvited') }
    }
    setInviteUserFormState(2)
  }

  return (
    <>
      {inviteUserFormState === 1 && (
        <Form
          initialValues={{}}
          onSubmit={handleSubmitEmail}
          render={({ handleSubmit, dirty, submitErrors, dirtySinceLastSubmit, submitting }) => {
            useBeforeUnload({ when: dirty })
            dirty !== isFormDirty && setDirtyFormState(dirty)
            return (
              <form className="flex flex-col mt-6 py-0 px-2" onSubmit={handleSubmit}>
                <Flex className="w-80">
                  <Field name="email">
                    {({ input, meta }) => {
                      return (
                        <Input
                          className="w-full"
                          errorMessage={
                            meta.touched &&
                            (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) &&
                            !meta.submitting
                              ? meta.error || meta.submitError
                              : undefined
                          }
                          id={input.name}
                          label={t('email')}
                          placeholder={t('email')}
                          tabIndex="1"
                          type="email"
                          {...input}
                        />
                      )
                    }}
                  </Field>
                </Flex>

                <div className="w-full mt-6 flex flex-row justify-end">
                  <Button
                    className="mr-2"
                    disabled={loading}
                    label={t('cancel')}
                    onClick={requestClose}
                    variant="tertiary"
                  />
                  <Button
                    disabled={(!dirtySinceLastSubmit && submitErrors) || submitting}
                    label={t('next')}
                    type="submit"
                  />
                </div>

                <AlertModal confirmClose={closeForm} />
              </form>
            )
          }}
          validate={(values) => ({
            email: validateRequiredField(values.email) || validateEmail(values.email),
          })}
        />
      )}
      {inviteUserFormState === 2 && (
        <BuyerUserForm
          cancelButtonTestData="cancel-invite-user"
          closeForm={closeForm}
          handleSubmitForm={handleSubmitForm}
          initialValues={initialValues}
          isFormDirty={isFormDirty}
          isInactiveContract={isInactiveContract}
          isInvitationAccepted={buyerUser?.invitationAcceptedAt}
          loading={loading || loadingUserData}
          loadingNotificationData={loadingNotificationData}
          requestClose={requestClose}
          setDirtyFormState={setDirtyFormState}
          submitButtonLabelKey="add"
          submitButtonTestData="submit-add-user"
        />
      )}
    </>
  )
}

InviteBuyerUserForm.propTypes = {
  buyerId: PT.oneOfType([PT.string, PT.number]).isRequired,
  closeForm: PT.func.isRequired,
  isFormDirty: PT.bool.isRequired,
  requestClose: PT.func.isRequired,
  setIsOpenedModal: PT.func,
  setDirtyFormState: PT.func.isRequired,
  refetch: PT.func,
  contractId: PT.string,
  isInactiveContract: PT.bool,
}

InviteBuyerUserForm.defaultProps = {
  refetch: () => {},
}

export default InviteBuyerUserForm
