import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Money, Text } from '../../../ui-kit'
import sizes from '../../../ui-kit/sizes'
import StatusBadge from '../../../ui-kit/components/badges/StatusBadge'
import { depositStatusMapping } from '../../invoices/invoicesUtils'
import PropTypes from 'prop-types'
import ActivityCardDate from './ActivityCardDate'
import ActivityCardGeneralInfo from './ActivityCardGeneralInfo'
import ActivityCardStatus from './ActivityCardStatus'
import { getCreatedByEvent } from '../utils'
import { getPaymentMethodNickName } from '../../payments/paymentsTab/util'

const DepositActivity = ({ deposit }) => {
  const { t } = useTranslation()
  return (
    <Flex justifyContent="between">
      <Flex>
        <ActivityCardDate date={deposit?.createdAt} />
        <ActivityCardGeneralInfo
          detailsValue={getCreatedByEvent(deposit)}
          entityId={deposit?.entity?.depositId}
          entityLabelKey="deposit"
        />
      </Flex>

      <Flex className="ml-4 w-[620px]" justifyContent="between">
        <Flex>
          <Flex shrink={0}>
            <Flex column>
              <Text className="mt-1" color="text-black-500">
                {t('depositAmount')}:
              </Text>
              <Text className="mt-3" color="text-black-500">
                {t('paymentMethod')}:
              </Text>
            </Flex>

            <Flex className="ml-1" column>
              <Money className="font-semibold text-xl" value={deposit?.entity?.amountCents} />
              <Text className="mt-2" size={sizes.SM}>
                {deposit?.entity?.latestPaymentTransaction?.paymentMethod
                  ? deposit?.entity?.latestPaymentTransaction?.paymentMethod?.title +
                    getPaymentMethodNickName(
                      deposit?.entity?.latestPaymentTransaction?.paymentMethod?.nickname,
                    )
                  : '-'}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <ActivityCardStatus
          lastUpdatesDate={deposit.lastUpdateDate}
          statusNode={
            <StatusBadge
              color={depositStatusMapping[deposit?.entity?.displayStatus]?.color}
              value={t(depositStatusMapping[deposit?.entity?.displayStatus]?.label)}
            />
          }
        />
      </Flex>
    </Flex>
  )
}

export default DepositActivity
DepositActivity.propTypes = {
  deposit: PropTypes.object.isRequired,
}
