import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { LoadingSpinner, PageDialog } from '../../../ui-kit'
import DataGridComponent from '../../../components/dataGrid/DataGridComponent'
import ReportFooter from '../reportFooter/ReportFooter'
import { getInactiveUsersColumns, getInactiveUsersFiltersColumns } from './logic'
import { getReportsData } from '../logic/reportsUtils'
import ReportsFilters from '../reportsFilters/ReportsFilters'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { getPaginationData } from '../../../utils/utils'

const InactiveUsers = ({
  data,
  isOpened,
  closeReport,
  reportMetadata,
  authToken,
  userFilters,
  page,
}) => {
  const { t } = useTranslation()
  const { queryParams } = useQueryParams()
  const columns = useMemo(() => getInactiveUsersColumns(t), [t])
  const rows = useMemo(() => getReportsData(data), [data])
  const filterColumns = useMemo(() => getInactiveUsersFiltersColumns(t), [t])
  const paginationData = getPaginationData(data)

  return (
    <PageDialog
      bottomPanel={
        <ReportFooter
          authToken={authToken}
          closeReport={closeReport}
          reportMetadata={reportMetadata}
          sort={queryParams.sort}
          userFilters={userFilters}
        />
      }
      isOpened={isOpened}
      setIsOpened={closeReport}
      title={t('unacceptedUsers')}>
      {!data ? (
        <LoadingSpinner className="h-full w-full" loading />
      ) : (
        <div className="px-8 w-full">
          <ReportsFilters availableFilters={data.availableFilters} columns={filterColumns} />

          <div className="pt-4">
            <DataGridComponent
              columns={columns}
              getRowId={(row) => `${row.membershipId}`}
              page={page}
              pageSize={100}
              paginationData={paginationData}
              rows={rows}
            />
          </div>
        </div>
      )}
    </PageDialog>
  )
}

InactiveUsers.propTypes = {
  data: PropTypes.object,
  page: PropTypes.number,
  isOpened: PropTypes.bool.isRequired,
  authToken: PropTypes.string.isRequired,
  closeReport: PropTypes.func.isRequired,
  userFilters: PropTypes.arrayOf(PropTypes.any),
  reportMetadata: PropTypes.shape({
    csvUrl: PropTypes.string,
    xlsxUrl: PropTypes.string,
    jsonUrl: PropTypes.string,
    fileNameKey: PropTypes.string,
    defaultFilters: PropTypes.object,
  }).isRequired,
}

export default InactiveUsers
